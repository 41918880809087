import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const autoPlayingStateAtom = atom({
  key: "autoPlayingStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],
})

export const autoPlayingStateSelector = selector({
  key: "autoPlayingStateSelector",
  get: ({ get }) => {
    const autoPlaying = get(autoPlayingStateAtom)
    return autoPlaying
  }
})

