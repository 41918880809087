import { atom, selector } from "recoil"

export const currentHowlerPlayIdStateAtom = atom({
  key: "currentHowlerPlayIdStateAtom",
  default: 0,
})

export const currentHowlerPlayIdStateSelector = selector({
  key: "currentHowlerPlayIdStateSelector",
  get: ({ get }) => {
    const currentHowlerPlayId = get(currentHowlerPlayIdStateAtom)
    return currentHowlerPlayId
  }
})

