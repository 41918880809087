import { styled } from '@mui/material/styles'

const Widget = styled('div')(({ theme }) => ({
  padding: 16,
  borderRadius: 16,
  maxWidth: 343,
  margin: 'auto',
  position: 'relative',
  backgroundColor:
  theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.4)',
  backdropFilter: 'blur(40px)',
}))

export default Widget
