import { atom, selector } from "recoil"

export const ConnectingToDeviceQrReaderStateAtom = atom({
  key: "ConnectingToDeviceQrReaderStateAtom",
  default: false,
})

export const ConnectingToDeviceQrReaderStateSelector = selector({
  key: "ConnectingToDeviceQrReaderStateSelector",
  get: ({ get }) => {
    const ConnectingToDeviceQrReader = get(ConnectingToDeviceQrReaderStateAtom)
    return ConnectingToDeviceQrReader
  }
})
