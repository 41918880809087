import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'

import { currentTrackStateAtom } from '@state/currentTrack'
import { trackIndexStateAtom } from '@state/trackIndex'
import { isPlayingStateAtom } from '@state/isPlaying'

import { playListsStateAtom, playListsI } from '@state/playLists'

import { Get } from '@components/File'

import { useHowler } from '@hooks/useHowler'

export const usePrevious = () => {
  
  const { play, pause, changeTrack } = useHowler()

  const [trackIndex , setTrackIndex] = useRecoilState<any>(trackIndexStateAtom)

  const setCurrentTrack = useSetRecoilState<any>(currentTrackStateAtom)

  const [isPlaying , setIsPlaying] = useRecoilState<boolean>(isPlayingStateAtom)

  const playLists = useRecoilValue<playListsI>(playListsStateAtom)

  const Previous = async (local?: boolean) => {
    return new Promise<boolean>(async(resolve, reject) => {
        try {

          let lastTrackIndex: number = 0
          
          let track: any = null
          
          for( let key in playLists.lists ){
            
            if( playLists.lists[key].id === playLists.open ){
      
              lastTrackIndex = playLists.lists[key].tracks.length - 1
      
              if (trackIndex === 0) {
      
                setTrackIndex(lastTrackIndex)
                setCurrentTrack(playLists.lists[key].tracks[lastTrackIndex])
      
                track = playLists.lists[key].tracks[lastTrackIndex]
              }else{
      
                setTrackIndex((prev:any) => prev - 1)
                setCurrentTrack(playLists.lists[key].tracks[trackIndex - 1])
      
                track = playLists.lists[key].tracks[trackIndex - 1]
              }
              break;
            }
          }
      
          if(track){
      
            const trackGetData = await Get(track)

            await changeTrack({
              src: [trackGetData],
              format: ['mp3'],
            })

            play()

            navigator.mediaSession.metadata = new MediaMetadata({
              title: track.name,
              //artist: AppTitle,
            })
/*
            const actionHandlers:any = [
              ['play',          () => { play() }],
              ['pause',         () => { pause() }],
              ['previoustrack', () => { Previous() }],
              ['nexttrack',     () => {  }],
              ['stop',          () => { pause() }],
            ]
            
            for (const [action, handler] of actionHandlers) {
              try {
                navigator.mediaSession.setActionHandler(action, handler);
              } catch (error) {
                console.log(`The media session action "${action}" is not supported yet.`);
              }
            }
*/
            if(!isPlaying) setIsPlaying(true)
            
            if(!local) return;
      
            /*
            
            if(socket) socket.emit('chat', {
              message: {
                sender: device.uuid,
                message: {
                  action: 'prev',
                  data: null,
                }
              },
              room: device.room
            })
      
            */
          }
      
          resolve(true)
          
        } catch (error) {
          reject(error)
        }
    })
    
  }

  return [Previous] as const;
}