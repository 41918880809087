import { FC } from 'react'

import { FixedSizeList as List } from "react-window"

import { useRecoilState } from 'recoil'

import { playListsStateAtom, playListsI, ListsI, TrackI } from '@state/playLists'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Stack from '@mui/material/Stack'

import useMediaQuery from '@mui/material/useMediaQuery'

import AnimationIcon from '@mui/icons-material/Animation';
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'

const AddTrackToPlayList: FC<any> = ({
  track,
  open,
  setOpen
}) => {

  const [ playLists, setPlayLists ] = useRecoilState<playListsI>(playListsStateAtom)

  const matches = useMediaQuery('(min-width:370px)')

  const Row = ({ index, style }:any) => {

    let item:any
    
    for (let i = 0; i < playLists.lists.length; i++) {
        if(i===index){
        item = playLists.lists[i]
      }
    }

    const addToPlayList = ( item: TrackI ) => {

      const cloneTrack: TrackI = {...track, original: track.id}

      setPlayLists({...playLists, lists: playLists.lists.map(( list:ListsI ) => {
        if ( list.id === item.id ) {
            return {...list, tracks: [ ...list.tracks, cloneTrack]}
        }
        return list
      })})

      setOpen(false)
      
    }

    return <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" style={style}> 

              <Typography variant="tinyText">{ item.name }</Typography>

              { item.id !== playLists.open ?
                <IconButton aria-label="Add" onClick={()=>{ addToPlayList(item) }}>
                  <AddIcon />
                </IconButton>
                :
                <IconButton aria-label="Add" onClick={()=>{ addToPlayList(item) }}>
                  <AnimationIcon />
                </IconButton>
              }
            </Stack>
  }

  return (
    <Box sx={{ maxHeight: '100vh' }}>

      <SwipeableDrawer
        anchor={`bottom`}
        open={open}
        disableAutoFocus
        disableEnforceFocus
        
        onClose={()=>{setOpen(false)}}
        onOpen={()=>{setOpen(true)}}
        sx={{
          'justify-content': 'center','display': 'flex', 'align-items': 'center',
          '& .MuiPaper-root': {

            position: 'absolute',

            border: '0px solid #fd0000 !important',
            backgroundColor: 'rgba(255, 255, 255, .7)',
            backdropFilter: "blur(10px)",

            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,

            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,

            borderColor: 'none',

            padding: '25px',

            height: 100,

            width: 'calc( 100vw - 80px)',
            left: matches ? `calc(50% - 174px)` : 15,

            bottom: '15px',
          },
        }}
      >
        <Box display="flex" flexDirection="column" alignContent={'center'} justifyContent={'space-evenly'} height={160}>

          <List
            height={100}
            itemCount={playLists.lists.length}
            itemSize={35}
            width={'100%'}
          >
            {Row}
          </List>

        </Box>
        
      </SwipeableDrawer>

    </Box>
  )
}

export default AddTrackToPlayList
