import { atom, selector } from "recoil"

export const moreStateAtom = atom({
  key: "moreStateAtom",
  default: false,

})

export const moreStateSelector = selector({
  key: "moreStateSelector",
  get: ({ get }) => {
    const more = get(moreStateAtom)
    return more
  }
})

