
import { useEffect, useContext } from "react"

import { useRecoilState } from 'recoil'

import { SocketContext } from '@context/socket'

import { deviceStateAtom, deviceI } from '@state/device'

import deviceUuid from '@utils/uuid'

export const useWebSocket = () => {

  const socket = useContext(SocketContext)
  const [device, setDevice] = useRecoilState<deviceI>(deviceStateAtom)

  useEffect(()=>{

    if( !device.uuid ){

      const uuid = deviceUuid()

      setDevice({ ...device, uuid: uuid, room: uuid})

    }else{
      if( device.type === 'client-server' ){

        if (socket) {

          console.log(`subscribe (set socket room): ${device.uuid}`)

          socket.emit('subscribe', device.uuid)
        }
      }
    }
  }, [socket, device, setDevice])

  useEffect(() => {

    if(!socket) return
    
    socket.on("socket_id", (id: string) => {

      if (socket && device.room ) socket.emit("subscribe", device.room)
    })

    socket.on('chat', msg => {

      console.log('chat 1')
      console.log(msg)
    })

    return () => {
      socket.off("socket_id")
      socket.off("chat")
    };
  }, [socket, device.room])

  const sendToRoom = ( message:any ) => {

    if( socket && device.room ) socket.emit('chat', {
      message: {
        sender: device.uuid,
        action: message.action, //'play', 'stop'
        data: message.data, // any
      },
      room: device.room
    })

    if (socket && device.room ) socket.emit("subscribe", device.room)
  }

  const setRoom = ( id: string | undefined ) => {
    
    setDevice({ ...device, room: id})

    if ( socket && device.room ) socket.emit("subscribe", id)
  }

  return {sendToRoom, setRoom}
}