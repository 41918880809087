import { FC } from 'react'

import { useRecoilState } from 'recoil'
import { loopStateAtom } from '@state/loop'
import { autoPlayingStateAtom } from '@state/autoPlaying'
import { randomPauseStateAtom } from '@state/randomPause'
import { randomVolumeStateAtom } from '@state/randomVolume'
import { onlyAtPermittedTimesStateAtom } from '@state/onlyAtPermittedTimes'
import { userRandomPauseStateAtom } from '@state/userRandomPause'
import { userRandomVolumeStateAtom } from '@state/userRandomVolume'
import { settingsStateAtom } from '@state/settings'

import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'

import Switch from '@mui/material/Switch'

import InputAdornment from '@mui/material/InputAdornment'
import Input from '@mui/material/Input'

const Settings: FC<any> = () => {

  const [settings, setSettings] = useRecoilState<boolean>(settingsStateAtom)

  const [isLoop, setIsLoop] = useRecoilState<boolean>(loopStateAtom)
  const [autoPlaying, setAutoPlaying] = useRecoilState<boolean>(autoPlayingStateAtom)
  
  const [randomPause, setRandomPause] = useRecoilState<boolean>(randomPauseStateAtom)
  const [userRandomPause, setUserRandomPause] = useRecoilState<any>(userRandomPauseStateAtom)
  const [userRandomVolume, setUserRandomVolume] = useRecoilState<any>(userRandomVolumeStateAtom)

  const [randomVolume, setRandomVolume] = useRecoilState<boolean>(randomVolumeStateAtom)
  
  const [onlyAtPermittedTimes, setOnlyAtPermittedTimes] = useRecoilState<any>(onlyAtPermittedTimesStateAtom)

  return (
        <SwipeableDrawer
          anchor={`left`}
          open={settings}
          onClose={()=>{setSettings(false)}}
          onOpen={()=>{setSettings(true)}}
        >

          <Typography variant="h6" alignItems="center" justifyContent="center" sx={{ mt: 2,  }}>
            Настройки
          </Typography>

          <Stack spacing={2} direction="row" sx={{ mt: 1, mb: 1, px: 1 }} alignItems="center">

            <Switch
            checked={autoPlaying}
            onChange={()=>{setAutoPlaying(!autoPlaying)}}
            inputProps={{ 'aria-label': 'autoPlaying' }}
            color="default" />

            <Typography variant="tinyText">Автовоспроизведение</Typography>

          </Stack>

          <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">

            <Switch
            checked={isLoop}
            onChange={()=>{setIsLoop(!isLoop)}}
            inputProps={{ 'aria-label': 'isLoop' }}
            color="default" />

            <Typography variant="tinyText">Зациклить</Typography>

          </Stack>

          <Box>

            <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">

              <Switch
              checked={randomPause}
              onChange={()=>{setRandomPause(!randomPause)}}
              inputProps={{ 'aria-label': 'randomPause' }}
              color="default" />

              <Typography variant="tinyText">Случайная пауза (У каждого трека она своя)</Typography>

            </Stack>

            <Fade in={randomPause} unmountOnExit={true}>
                
              <Box sx={{ mb: 1 }}>

                <Stack spacing={2}  sx={{ ml: 2,mr: 2, pt: 1, pb:1,  px: 1, border: '2px solid rgb(0, 0, 0, 0.1)', borderRadius: 2 }} alignItems="start">
                
                  <Stack spacing={2} direction="row" sx={{ }} alignItems="center">

                    <Switch
                    checked={userRandomPause.status}
                    onChange={()=>{
                      setUserRandomPause({ ...userRandomPause, status: !userRandomPause.status })
                    }}
                    inputProps={{ 'aria-label': 'userRandomPause' }}
                    color="default" />

                    <Typography variant="tinyText">Пользовательская случайная пауза (Секунды)</Typography>
                                    
                  </Stack>

                  <Box display={'flex'}>
                    
                    <Input defaultValue={userRandomPause.min} onChange={(e:any) => {
                        let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                        if (onlyNums.length > 0) {
                          onlyNums = Number(onlyNums)
                          if(onlyNums < 1) return;
                          setUserRandomPause({ ...userRandomPause, min: onlyNums })
                        }
                    }}
                    startAdornment={<InputAdornment position="start" sx={{
                      padding: "5px",
                      marginBottom: 0.5,
                      height: '100%',
                      borderRadius: 2,
                    }}>
                      <Typography variant="tinyText">ОТ</Typography>
                    </InputAdornment>}
                    />

                    <Input defaultValue={userRandomPause.max} onChange={(e:any) => {
                      let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                      if (onlyNums.length > 0) {
                        onlyNums = Number(onlyNums)
                        if(onlyNums > 100) return;
                        setUserRandomPause({ ...userRandomPause, max: onlyNums })
                      }
                    }}
                    startAdornment={<InputAdornment position="start" sx={{
                      padding: "5px",
                      marginBottom: 0.5,
                      height: '100%',
                      borderRadius: 2,
                    }}>
                      <Typography variant="tinyText">ДО</Typography>
                    </InputAdornment>}
                    />

                  </Box>
      
                </Stack>

              </Box>

            </Fade>

          </Box>

          <Box>

            <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">

              <Switch
              checked={randomVolume}
              onChange={()=>{setRandomVolume(!randomVolume)}}
              inputProps={{ 'aria-label': 'randomVolume' }}
              color="default" />

              <Typography variant="tinyText">Случайная громкость (50% - 100%)</Typography>

            </Stack>

            <Fade in={randomVolume} unmountOnExit={true}>

              <Box sx={{ mb: 1 }}>

                <Stack spacing={2}  sx={{ ml: 2,mr: 2, pt: 1, pb:1,  px: 1, border: '2px solid rgb(0, 0, 0, 0.1)', borderRadius: 2 }} alignItems="start">
                
                  <Stack spacing={2} direction="row" sx={{ }} alignItems="center">

                    <Switch
                    checked={userRandomVolume.status}
                    onChange={()=>{
                      setUserRandomVolume({ ...userRandomVolume, status: !userRandomVolume.status })
                    }}
                    inputProps={{ 'aria-label': 'userRandomVolume' }}
                    color="default" />

                    <Typography variant="tinyText">Пользовательская случайная громкость</Typography>
                                    
                  </Stack>

                  <Box display={'flex'}>
                    
                    <Input defaultValue={userRandomVolume.min} onChange={(e:any) => {
                        let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                        if (onlyNums.length > 0) {
                          onlyNums = Number(onlyNums)
                          if(onlyNums < 1) return;
                          setUserRandomVolume({ ...userRandomVolume, min: onlyNums })
                        }
                    }}
                    startAdornment={<InputAdornment position="start" sx={{
                      padding: "5px",
                      marginBottom: 0.5,
                      height: '100%',
                      borderRadius: 2,
                    }}>
                      <Typography variant="tinyText">ОТ</Typography>
                    </InputAdornment>}
                    />

                    <Input defaultValue={userRandomVolume.max} onChange={(e:any) => {
                      let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                      if (onlyNums.length > 0) {
                        onlyNums = Number(onlyNums)
                        if(onlyNums > 100) return;
                        setUserRandomVolume({ ...userRandomVolume, max: onlyNums })
                      }
                    }}
                    startAdornment={<InputAdornment position="start" sx={{
                      padding: "5px",
                      marginBottom: 0.5,
                      height: '100%',
                      borderRadius: 2,
                    }}>
                        <Typography variant="tinyText">ДО</Typography>
                      </InputAdornment>}
                    />

                  </Box>
      
                </Stack>

              </Box>

            </Fade>

          </Box>

          <Box>

            <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">

              <Switch
              checked={onlyAtPermittedTimes.status}
              onChange={()=>{
                if(!onlyAtPermittedTimes) setAutoPlaying(true)
                setOnlyAtPermittedTimes({ ...onlyAtPermittedTimes, status: !onlyAtPermittedTimes.status })
              }}
              inputProps={{ 'aria-label': 'onlyAtPermittedTimes' }}
              color="default" />
              
              <Typography variant="tinyText">Только в разрешенное время (Должно быть включённое Автовоспроизведение)</Typography>

            </Stack>

            <Fade in={onlyAtPermittedTimes.status} unmountOnExit={true}>

              <Box sx={{ mb: 1 }}>

                <Stack spacing={2}  sx={{ ml: 2,mr: 2, pt: 1, pb:1,  px: 1, border: '2px solid rgb(0, 0, 0, 0.1)', borderRadius: 2 }} alignItems="start">
                  
                  <Typography variant="tinyText">Днем (так называемый тихий час)</Typography>

                  <Box display={'flex'}>
                    
                    <Input defaultValue={onlyAtPermittedTimes.day.from} onChange={(e:any) => {
                      let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                      if (onlyNums.length > 0) {
                        onlyNums = Number(onlyNums)
                        if(onlyNums < 1) return;
                        setOnlyAtPermittedTimes({ ...onlyAtPermittedTimes, day: {
                          from: onlyNums,
                          to: onlyAtPermittedTimes.day.to,
                        } })
                      }
                    }}
                    startAdornment={<InputAdornment position="start" sx={{
                      padding: "5px",
                      marginBottom: 0.5,
                      height: '100%',
                      borderRadius: 2,
                    }}>
                      <Typography variant="tinyText">С</Typography>
                    </InputAdornment>}
                    />
                    
                    <Input defaultValue={onlyAtPermittedTimes.day.to} onChange={(e:any) => {
                      let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                      if (onlyNums.length > 0) {
                        onlyNums = Number(onlyNums)
                        if(onlyNums > 23) return;
                        setOnlyAtPermittedTimes({ ...onlyAtPermittedTimes, day: {
                          from: onlyAtPermittedTimes.day.from,
                          to: onlyNums,
                        } })
                      }
                    }}
                    startAdornment={<InputAdornment position="start" sx={{
                      padding: "5px",
                      marginBottom: 0.5,
                      height: '100%',
                      borderRadius: 2,
                    }}>
                      <Typography variant="tinyText">ДО</Typography>
                    </InputAdornment>}
                    />

                  </Box>
      
                </Stack>
                
                <Stack spacing={2}  sx={{ ml: 2,mr: 2, pt: 1, pb:1,  px: 1, border: '2px solid rgb(0, 0, 0, 0.1)', borderRadius: 2 }} alignItems="start">

                  <Typography variant="tinyText">Ночью</Typography>

                  <Box display={'flex'}>
                    
                    <Input defaultValue={onlyAtPermittedTimes.night.from} onChange={(e:any) => {
                      let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                      if (onlyNums.length > 0) {
                        onlyNums = Number(onlyNums)
                        if(onlyNums < 1) return;
                        setOnlyAtPermittedTimes({ ...onlyAtPermittedTimes, night: {
                          from: onlyNums,
                          to: onlyAtPermittedTimes.night.to,
                        } })
                      }
                    }}
                    startAdornment={<InputAdornment position="start" sx={{
                      padding: "5px",
                      marginBottom: 0.5,
                      height: '100%',
                      borderRadius: 2,
                    }}>
                      <Typography variant="tinyText">С</Typography>
                    </InputAdornment>}
                    />
                    
                    <Input defaultValue={onlyAtPermittedTimes.night.to} onChange={(e:any) => {
                      let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                      if (onlyNums.length > 0) {
                        onlyNums = Number(onlyNums)
                        if(onlyNums > 23) return;
                        setOnlyAtPermittedTimes({ ...onlyAtPermittedTimes, night: {
                          from: onlyAtPermittedTimes.night.from,
                          to: onlyNums,
                        } })
                      }
                    }}
                    startAdornment={<InputAdornment position="start" sx={{
                      padding: "5px",
                      marginBottom: 0.5,
                      height: '100%',
                      borderRadius: 2,
                    }}>
                      <Typography variant="tinyText">ДО</Typography>
                    </InputAdornment>}
                    />

                  </Box>
      
                </Stack>

              </Box>

            </Fade>

          </Box>

        </SwipeableDrawer>
        )
}

export default Settings