import { atom, selector } from "recoil"

export const LoadFileCountUpdateStateAtom = atom({
  key: "LoadFileCountUpdateStateAtom",
  default: 0,
})

export const LoadFileCountUpdateStateSelector = selector({
  key: "LoadFileCountUpdateStateSelector",
  get: ({ get }) => {
    const LoadFileCountUpdate = get(LoadFileCountUpdateStateAtom)
    return LoadFileCountUpdate
  }
})

