import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const randomVolumeStateAtom = atom({
  key: "randomVolumeStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],

})

export const randomVolumeStateSelector = selector({
  key: "randomVolumeStateSelector",
  get: ({ get }) => {
    const randomVolume = get(randomVolumeStateAtom)
    return randomVolume
  }
})

