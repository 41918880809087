import { FC, useEffect } from 'react'

import { useRecoilState } from 'recoil'

import { volumeStateAtom } from '@state/volume'
import { isPlayingStateAtom } from '@state/isPlaying'

import { useTheme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import PauseRounded from '@mui/icons-material/PauseRounded'
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded'
import FastForwardRounded from '@mui/icons-material/FastForwardRounded'
import FastRewindRounded from '@mui/icons-material/FastRewindRounded'
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded'
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded'

import { usePrevious } from "@hooks/usePrevious"
import { useNext } from "@hooks/useNext"

import { useHowler } from '@hooks/useHowler'

import CurrentTime from '@components/CurrentTime'
import OnlyAtPermittedTimes from '@components/OnlyAtPermittedTimes'

const Controls: FC<any> = () => {

  const { audio, play, pause } = useHowler()

  const [Previous] = usePrevious()
  const [Next] = useNext()

  const [volume, setVolume] = useRecoilState<number>(volumeStateAtom)
  const [isPlaying, setIsPlaying] = useRecoilState<boolean>(isPlayingStateAtom)

  useEffect(() => {

    audio.current.volume(volume/100)

  }, [volume])

  useEffect(() => {

    if (isPlaying) {

      play()
      
    } else {

      pause()
    }
  }, [isPlaying])
  
  const theme = useTheme()

  const mainIconColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.8)'
  const lightIconColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.8)'

  return (
    <>

      <CurrentTime/>
      <OnlyAtPermittedTimes/>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: -1,
        }}
      >
        <IconButton aria-label="previous song" onClick={()=>{Previous()}}>
          <FastRewindRounded fontSize="large" htmlColor={mainIconColor}/>
        </IconButton>
        <IconButton
          aria-label={isPlaying ? 'play' : 'pause'}
          onClick={() => setIsPlaying(!isPlaying)}
        >
          {!isPlaying ? (
            <PlayArrowRounded
              sx={{ fontSize: '3rem' }}
              htmlColor={mainIconColor}
            />
          ) : (
            <PauseRounded sx={{ fontSize: '3rem' }} htmlColor={mainIconColor} />
          )}
        </IconButton>
        <IconButton aria-label="next song" onClick={()=>{Next()}}>
          <FastForwardRounded fontSize="large" htmlColor={mainIconColor}/>
        </IconButton>
      </Box>
      
      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
        <IconButton aria-label="VolumeDownRounded" onClick={()=>{setVolume(0)}}>
          <VolumeDownRounded htmlColor={lightIconColor} />
        </IconButton>
        <Slider
          aria-label={`Volume ${volume}`}
          value={volume}
          onChange={(event: Event, value: number | number[])=>{
            setVolume(value as number)
          }}
          sx={{
            color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
            '& .MuiSlider-track': {
              border: 'none',
            },
            '& .MuiSlider-thumb': {
              width: 17,
              height: 17,
              '&:before': {
                boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
              },
              '&:hover, &.Mui-focusVisible, &.Mui-active': {
                boxShadow: 'none',
              },
            },
          }}
        />
        <IconButton aria-label="VolumeUpRounded" onClick={()=>{ setVolume(100) }}>
          <VolumeUpRounded htmlColor={lightIconColor} />
        </IconButton>
      </Stack>
    </>
  )
}

export default Controls
