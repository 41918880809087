import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const randomPauseStateAtom = atom({
  key: "randomPauseStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],

})

export const randomPauseStateSelector = selector({
  key: "randomPauseStateSelector",
  get: ({ get }) => {
    const randomPause = get(randomPauseStateAtom)
    return randomPause
  }
})

