import { FC, useEffect } from 'react'

import { useRecoilState } from 'recoil'

import { settingsStateAtom } from '@state/settings'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import SettingsIcon from '@mui/icons-material/Settings';

import { Upload } from '@components/File';

const Controls: FC<any> = () => {

  const [settings, setSettings] = useRecoilState<boolean>(settingsStateAtom)
  
  useEffect(() => {}, [settings, setSettings])

  return (
    <Box display={'flex'} justifyContent="space-between" alignItems="center" sx={{ mt: 1, mb: 1  }}>
{/*
      <Stack spacing={2} direction="row" sx={{ mt: 1, px: 1, cursor: 'default',  }} alignItems="center">

        <Typography variant="tinyText"> <Upload/> </Typography>

      </Stack>
   */}
      {/*
      <Stack spacing={2} direction="row" sx={{ mt: 1, px: 1, cursor: 'default',  }} alignItems="center">

        <Typography variant="tinyText">Разработчик: <Link href="https://genikineg.ru" target="_blank">Genikineg.ru</Link></Typography>

      </Stack>

      <Stack spacing={2} direction="row" sx={{ mt: 1, px: 1, cursor: 'pointer',  }} alignItems="center" onClick={()=>{setSettings(true)}}>
        
        <SettingsIcon/>

      </Stack>
      */}

    </Box>
  )
}

export default Controls
