import { FC, useEffect, useState, useRef } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'
import { durationStateAtom } from '@state/duration'
import { isPlayingStateAtom } from '@state/isPlaying'

import { currentTimeStateAtom } from '@state/currentTime'

import { useTheme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'

import audioFormatTime from '@utils/audioFormatTime'

import { useHowler } from '@hooks/useHowler'

interface ICurrentTime {}

const CurrentTime: FC<ICurrentTime> = () => {

  const duration = useRecoilValue<number>(durationStateAtom)
  const [isPlaying, setIsPlaying] = useRecoilState<boolean>(isPlayingStateAtom)

  const theme = useTheme()

  const { audio } = useHowler()

  const setIntervalRef = useRef<any>(null)
  const [interval, setinterval] = useState<boolean>(false)

  const requestRef = useRef<any>()
  const [currentTime, setCurrentTime] = useRecoilState<number>(currentTimeStateAtom)

  useEffect(() => {

    if(isPlaying){

      setIntervalRef.current = setInterval(() => {

        setCurrentTime( audio.current.seek() )
      }, 1000)

      setinterval(true)

    }else{

      clearInterval(setIntervalRef.current)
      setinterval(false)
    }
  }, [isPlaying])

/*
  const startAnimation = useCallback(() => {

    console.log('startAnimation')


      const playAnimation = () => {

        setCurrentTime( audio.current.seek() )

        requestRef.current = requestAnimationFrame(playAnimation)
      }
      requestAnimationFrame(playAnimation)
    
  }, [audio?.current?.seek(), setCurrentTime])

  const stopAnimation = useCallback(() => {

    cancelAnimationFrame(requestRef.current)
  }, [requestRef])

  useEffect(() => {

    if (isPlaying) {

      startAnimation()
    } else {

      stopAnimation()
    }
  }, [isPlaying, startAnimation, stopAnimation])
*/

  useEffect(() => {}, [currentTime, setCurrentTime])

  return (
    <>
      
      <Stack spacing={2} direction="row" alignItems="center">

        <Slider
          aria-label={`time-indicator ${currentTime}`}
          size="small"
          value={currentTime}
          min={0}
          step={1}
          max={duration}
          onChange={(event: Event, value: number | number[])=>{ 

            audio.current.seek((value as number))

            if (!isPlaying) {
              setIsPlaying(true)
            }
          }}
          sx={{
            color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
            height: 4,
            "& .MuiSlider-track": {
              transition: 'none'
            },
            '& .MuiSlider-thumb': {
              transition: 'none',
              width: 8,
              height: 8,
              '&:before': {
                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
              },
              '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 8px ${
                  theme.palette.mode === 'dark'
                    ? 'rgb(255 255 255 / 16%)'
                    : 'rgb(0 0 0 / 16%)'
                }`,
              },
              '&.Mui-active': {
                width: 20,
                height: 20,
              },
            },
            '& .MuiSlider-rail': {
              opacity: 0.28,
            },
          }}
        />

      </Stack>

      <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: -1,

      }}>
        <Typography variant="tinyText">{ audioFormatTime( currentTime ) }</Typography>
        <Typography variant="tinyText">{ audioFormatTime( duration - currentTime ) }</Typography>
      </Box>
      
    </>
  )
}
export default CurrentTime
