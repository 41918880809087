import { atom, selector } from "recoil"

export const backdropStateAtom = atom<boolean>({
  key: "backdropStateAtom",
  default: false,
})

export const backdropStateSelector = selector<boolean>({
  key: "backdropStateSelector",
  get: ({ get }) => {
    const backdrop = get(backdropStateAtom)
    return backdrop
  }
})

