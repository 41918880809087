import { atom, selector } from "recoil"

export const durationStateAtom = atom<number>({
  key: "durationStateAtom",
  default: 0,
})

export const durationStateSelector = selector<number>({
  key: "durationStateSelector",
  get: ({ get }) => {
    const duration = get(durationStateAtom)
    return duration
  }
})

