import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const endTracksTriggerStateAtom = atom({
  key: "endTracksTriggerStateAtom",
  default: 0,
  effects_UNSTABLE: [persistAtom],

})

export const endTracksTriggerStateSelector = selector({
  key: "endTracksTriggerStateSelector",
  get: ({ get }) => {
    const endTracksTrigger = get(endTracksTriggerStateAtom)
    return endTracksTrigger
  }
})

