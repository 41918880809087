import { atom, selector } from "recoil"

export const registrationStateAtom = atom({
  key: "registrationStateAtom",
  default: false,
})

export const registrationStateSelector = selector({
  key: "registrationStateSelector",
  get: ({ get }) => {
    const registration = get(registrationStateAtom)
    return registration
  }
})

