import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const settingsStateAtom = atom({
  key: "settingsStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],
})

export const settingsStateSelector = selector({
  key: "settingsStateSelector",
  get: ({ get }) => {
    const settings = get(settingsStateAtom)
    return settings
  }
})

