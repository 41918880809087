import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const onlyAtPermittedTimesStateAtom = atom({
  key: "onlyAtPermittedTimesStateAtom",
  default: {
    status: true,
    day: {
      from: 13,
      to: 15,
    },
    night: {
      from: 22,
      to: 10,
    },
  },
  effects_UNSTABLE: [persistAtom],
})

export const onlyAtPermittedTimesStateSelector = selector({
  key: "onlyAtPermittedTimesStateSelector",
  get: ({ get }) => {
    const onlyAtPermittedTimes = get(onlyAtPermittedTimesStateAtom)
    return onlyAtPermittedTimes
  }
})

