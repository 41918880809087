import { FC, CSSProperties, ReactNode, forwardRef, useRef, useEffect, useState, useCallback, memo } from "react"
import { FixedSizeList, areEqual } from "react-window"
import { DragDropContext, Droppable, DropResult, Draggable, DraggingStyle } from "react-beautiful-dnd"

import Box from '@mui/material/Box'

import { RowItem, ItemProps, ItemData } from './types'

import "./style.css"

import ListsItem from './ListsItem'

import { Ref } from 'react'

import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars-2'

interface ShadowScrollbarsProps {
  style?: CSSProperties
}

const ShadowScrollbars = ({ style, ...props }: ShadowScrollbarsProps): JSX.Element => {
  const [state, setState] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0
  })
  
  const handleUpdate = (values: {scrollTop: number, scrollHeight: number, clientHeight: number}) => {
    const { shadowTop, shadowBottom } = refs
    const { scrollTop, scrollHeight, clientHeight } = values
    const shadowTopOpacity = 1 / 20 * Math.min(scrollTop, 20)
    const bottomScrollTop = scrollHeight - clientHeight
    const shadowBottomOpacity = 1 / 20 * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20))
    //css(shadowTop, { opacity: shadowTopOpacity })
    //css(shadowBottom, { opacity: shadowBottomOpacity })
    setState(values)
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const shadowTopRef = useRef<HTMLDivElement>(null)
  const shadowBottomRef = useRef<HTMLDivElement>(null)
  const refs = { shadowTop: shadowTopRef.current, shadowBottom: shadowBottomRef.current }
  
  const containerStyle: CSSProperties = {
    ...style,
    position: 'relative'
  }
  
  const shadowTopStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 10,
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)'
  }
  
  const shadowBottomStyle: CSSProperties = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 10,
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)'
  }

  return (
    <div style={containerStyle} ref={containerRef}>
      <Scrollbars
        onUpdate={handleUpdate}
        {...props}
        />
      <div
        ref={shadowTopRef}
        style={shadowTopStyle}/>
      <div
        ref={shadowBottomRef}
        style={shadowBottomStyle}
        />
    </div>
  )
}

ShadowScrollbars.propTypes = {
  style: PropTypes.object
}

interface CustomScrollbarsProps {
  onScroll?: any
  forwardedRef?: any
  style?: CSSProperties;
  children?: ReactNode;
}

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }: CustomScrollbarsProps): JSX.Element => {
  
  const refSetter = useCallback((scrollbarsRef: any) => {
    if (scrollbarsRef) {
      forwardedRef?.(scrollbarsRef.view as HTMLDivElement);
    } else {
      forwardedRef?.(null);
    }
  }, [forwardedRef]);

  return (
    <Scrollbars
      ref={refSetter}
      style={{ ...style, overflow: "hidden" }}
      onScroll={onScroll}
    >
      {children}
    </Scrollbars>
  );
};

const CustomScrollbarsVirtualList = forwardRef((props: CustomScrollbarsProps, ref: Ref<HTMLDivElement>) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
))

const VirtualList: FC<any> = ({
  height=300,
  itemSize=80,
  items=[],
  reorderLists,
}) => {

  const reorder = (list: RowItem[], startIndex: number, endIndex: number): RowItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }
  
  const getStyle = ({ provided, style, isDragging }: ItemProps): CSSProperties & DraggingStyle => {
    const combined:any = {
      ...style,
      ...provided.draggableProps.style,
    }
  
    const marginBottom = 8;
    const withSpacing: CSSProperties & DraggingStyle = {
      ...combined,
      height: isDragging ? combined.height : combined.height - marginBottom,
      marginBottom,
    }
    return withSpacing;
  }
  
  const RowItem = memo(({ provided, item, style, isDragging }: ItemProps) => {
    
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={getStyle({ provided, style, isDragging })}
        className={`item ${isDragging ? "is-dragging" : ""}`}
      >

        <ListsItem item={item} />
      </div>
    )
  })
  
  const Row = memo(function Row(props: { data: ItemData; index: number; style: object }) {
    const { data: { items }, index, style } = props;
    const item = items[index]

    return (
      <Draggable draggableId={(item.id).toString()} index={index} key={item.id}>
        {(provided) => <RowItem provided={provided} item={item} style={style} isDragging={false} />}
      </Draggable>
    )
 
  }, areEqual)

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.source.index === result.destination.index) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    )

    reorderLists(newItems)
  }
   
  const [ scroll, setScroll] = useState(false)
  const [ scrollEndOfList, setScrollEndOfList] = useState(false)
  
  const onScroll = (props:any) => {
    const { scrollOffset } = props;

    if(scrollOffset > 11){
      if(!scroll){
        setScroll(true)

      }
    }else{
      if(scroll){
        setScroll(false)

      }
    }

    const lastScroll = itemSize * items.length - height;
    if (scrollOffset >= lastScroll) {
      setScrollEndOfList(true)
    }else{
      setScrollEndOfList(false)
    }
  }
  
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box
>
        <Droppable
          droppableId="droppable"
          mode="virtual"
          renderClone={(provided, snapshot, rubric) => (
            <RowItem
              provided={provided}
              isDragging={snapshot.isDragging}
              item={items[rubric.source.index]}
              style={provided.draggableProps.style}
            />
          )}
        >
          {(provided) => (
            <FixedSizeList
              //onScroll={onScroll}
              height={height}
              itemCount={items.length}
              itemSize={itemSize}
              width={'100%'}
              outerRef={provided.innerRef}

              itemData={{ items }}
              //className={ !scroll ? "no-scrollbars-bottom" : scrollEndOfList ? "no-scrollbars-top" : "no-scrollbars-two"}
              className={ "no-scrollbars" }
            >
              {Row}
            </FixedSizeList>
          )}
        </Droppable>
      </Box>
    </DragDropContext>
  )
}

export default VirtualList