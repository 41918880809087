import { useContext } from 'react'

import { soundContext } from '@context/sound'

import { usePrevious } from "@hooks/usePrevious"
import { useNext } from "@hooks/useNext"

export const useMediaSession = () => {

  const sound = useContext(soundContext)

  const [Previous] = usePrevious()
  const [Next] = useNext()

  const useMediaSession = async (local?: boolean) => {

    return new Promise<boolean>(async(resolve, reject) => {
        try {

          if ("mediaSession" in navigator) {

            const actionHandlers:any = [
              ['play',          () => { sound.current.play() }],
              ['pause',         () => { sound.current.pause() }],
              ['previoustrack', () => { Previous() }],
              ['nexttrack',     () => { Next() }],
              ['stop',          () => { /* ... */ }],
              //['seekbackward',  (details:any) => { /* ... */ }],
              //['seekforward',   (details:any) => { /* ... */ }],
              //['seekto',        (details:any) => { /* ... */ }],
            ];
            
            /*
            for (const [action, handler] of actionHandlers) {
              try {
                navigator.mediaSession.setActionHandler(action, handler);
              } catch (error) {
                console.log(`The media session action "${action}" is not supported yet.`);
              }
            }
*/
          }

          resolve(true)
          
        } catch (error) {
          reject(error)
        }
    })
    
  }

  return [useMediaSession] as const;
}