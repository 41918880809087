import { atom, selector } from "recoil"

export const loginStateAtom = atom({
  key: "loginStateAtom",
  default: false,
})

export const loginStateSelector = selector({
  key: "loginStateSelector",
  get: ({ get }) => {
    const login = get(loginStateAtom)
    return login
  }
})

