import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

import { deviceI } from "./types"

const { persistAtom } = recoilPersist()

export const deviceStateAtom = atom<deviceI>({
  key: "deviceStateAtom",
  default: {
    uuid: null,
    type: 'client-server',  // client-server, client, controller
    room: null,
  },
  effects_UNSTABLE: [persistAtom],
})

export const deviceStateSelector = selector<deviceI>({
  key: "deviceStateSelector",
  get: ({ get }) => {
    const device = get(deviceStateAtom)
    return device
  }
})

export type { deviceI }
