import React, { FC, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'

import LoginIcon from '@mui/icons-material/Login'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useRecoilState, useSetRecoilState } from 'recoil'
import { jwtStateAtom, jwtI } from '@state/jwt'
import { loginStateAtom } from '@state/login'
import { registrationStateAtom } from '@state/registration'

//import {SocialIcon} from 'react-custom-social-icons'

import axios from "axios"

const backendUrl = process.env.REACT_APP_BACKEND_URL

const initialUser = { password: "", identifier: "" }

interface ILogin {}

const Login: FC<ILogin> = () => {

  const [login, setLogin] = useRecoilState<boolean>(loginStateAtom)

  const [loading, setLoading] = useState<boolean>(false)

  const [user, setUser] = useState<any>(initialUser)

  const setJwt = useSetRecoilState<jwtI>(jwtStateAtom)
  const setRegistration = useSetRecoilState<any>(registrationStateAtom)

  const handleChange = (target:any) => {
    
    const { name, value } = target

    console.log(`name: ${name} value: ${value}`)

    setUser((currentUser:any) => ({
      ...currentUser,
      [name]: value,
    }))
  }

  const handleToRegistration = async () => {
    
    setLogin(false)

    setTimeout(()=>{

      setRegistration(true)
    }, 250)
  }

  const handleLogin = async () => {

    setLoading(true)

    const url = `${backendUrl}/api/auth/local`

    try {
      if (user.identifier && user.password) {

        const { data } = await axios.post(url, user)
        if (data.jwt) {

          console.log(data)

          setLoading(false)
          //setJwt({token: data.jwt})
          //setLogin(false)
        }
      }else{
        setTimeout(()=>{
          setLoading(false)

        }, 3000)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const matches = useMediaQuery('(min-width:370px)')

  return (
    <>

      <SwipeableDrawer
        anchor={`bottom`}
        open={login}
        disableAutoFocus
        disableEnforceFocus
        
        onClose={()=>{setLogin(false)}}
        onOpen={()=>{setLogin(true)}}
        sx={{
          'justify-content': 'center','display': 'flex', 'align-items': 'center',
          '& .MuiPaper-root': {

            position: 'absolute',

            border: '0px solid #fd0000 !important',
            backgroundColor: 'rgba(255, 255, 255, .7)',
            backdropFilter: "blur(10px)",

            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,

            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,

            borderColor: 'none',

            padding: '25px',

            width: 'calc( 100vw - 80px)',
            left: matches ? `calc(50% - 174px)` : 15,

            bottom: '15px',
          },
        }}
      >
        <Typography variant="h5" sx={{mb: 2.5, userSelect: 'none'}}>Вход в аккаунта</Typography>

        <TextField
          label="Емайл"
          type="email"
          name="identifier"
          value={user.identifier}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event.target)
          }}
          variant="standard"
          fullWidth
          sx={{mb: 1.5}}
        />

        <TextField
          label="Пароль"
          type="password"
          name="password"
          value={user.password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event.target)
          }}
          variant="standard"
          fullWidth
          sx={{mb: 3}}
        />

        <LoadingButton
          onClick={handleLogin}
          loading={loading}
          variant="outlined"
          fullWidth
          sx={{mb: 1}}
          endIcon={<LoginIcon />}
          loadingPosition="end"
        >
          { !loading && <>Войти</> }
        </LoadingButton>
        
        <Stack spacing={2} direction="column" alignItems="center">

          <Typography variant="body2" onClick={handleToRegistration} sx={{mt: 1.5, mb: 0.5, cursor: 'pointer' }}>Создать аккаунт</Typography>
        </Stack>

        <Stack spacing={2} direction="column" alignItems="center">

          <Typography variant="tinyText" sx={{mt: 1.5, userSelect: 'none' }}>Другие способы входа</Typography>
        </Stack>
        
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" sx={{pt: 1.5, cursor: 'pointer' }}>

          <Link href={`${backendUrl}/api/connect/google`} >
          {/*
            <SocialIcon network="google" shape="round" size="small" onClick={()=>{
              window.location.href = `${backendUrl}/api/connect/google`
            }}/>
            */}
          </Link>
          {/*
            <SocialIcon network="telegram" shape="round" size="small"/>
          */}
        </Stack>

      </SwipeableDrawer>
    </>
  )
}

export default Login
