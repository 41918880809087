import { FC, useState, useRef, useEffect } from 'react'

import { useRecoilState } from 'recoil'
import { playListsStateAtom, playListsI, ListsI } from '@state/playLists'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'

import { v4 as uuidv4 } from 'uuid'

const PlayLists: FC<any> = () => {

  const [ playLists, setPlayLists ] = useRecoilState<playListsI>(playListsStateAtom)

  const [ addPlayListPopup, setAddPlayListPopup ] = useState<boolean>(false)
  const [ addPlayListName, setAddPlayListName ] = useState<string>('')

  const [ deletePlayListPopup, setDeletePlayListPopup ] = useState<boolean>(false)
  const [ deletePlayListId, setDeletePlayListId ] = useState<string>('not')
  const [ deletePlayListName, setDeletePlayListName ] = useState<string>('no name')

  const matches = useMediaQuery('(min-width:370px)')

  const handleAdd = () => {
    console.info('You clicked the Chip.')
    setAddPlayListPopup(true)
  }

  const handleDelete = (item: any) => {
    console.info(`You clicked the delete id: ${item.id}`)
    setDeletePlayListId(item.id)
    setDeletePlayListPopup(true)
    setDeletePlayListName(item.name) 
  }

  const containerRef = useRef(null)

  const [checkedOnlineAccess, setCheckedOnlineAccess] = useState<boolean>(true)

  const handleChangecheckedOnlineAccess = () => { 
    
    setCheckedOnlineAccess(!checkedOnlineAccess)
  }

  useEffect(() => {
    const container:any = containerRef.current
  
    let isDown = false
    let startX:any
    let scrollLeft:any
  
    const handleMouseDown = (e:any) => {
      isDown = true
      startX = e.pageX - container.offsetLeft
      scrollLeft = container.scrollLeft
    }
  
    const handleMouseUp = (e:any) => {
      isDown = false;
    }
  
    const handleMouseMove = (e:any) => {
      if (!isDown) return;
      e.preventDefault()
      const x = e.pageX - container.offsetLeft
      const walk = (x - startX) * 1
      container.scrollLeft = scrollLeft - walk
    }
    const handleMouseLeave = () => {
      isDown = false
    }
    container.addEventListener('mousedown', handleMouseDown)
    container.addEventListener('mouseup', handleMouseUp)
    container.addEventListener('mousemove', handleMouseMove)
    container.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      container.removeEventListener('mousedown', handleMouseDown)
      container.removeEventListener('mouseup', handleMouseUp)
      container.removeEventListener('mousemove', handleMouseMove)
      container.removeEventListener('mouseleave', handleMouseLeave)
    };
  }, [])

  const setOpenList = ( id: string ) => {
    setPlayLists({...playLists, open: id})
  }

  return (
    <Box sx={{ maxHeight: '100vh' }}>

      <SwipeableDrawer
        anchor={`bottom`}
        open={addPlayListPopup}
        disableAutoFocus
        disableEnforceFocus
        
        onClose={()=>{setAddPlayListPopup(false)}}
        onOpen={()=>{setAddPlayListPopup(true)}}
        sx={{
          'justify-content': 'center','display': 'flex', 'align-items': 'center',
          '& .MuiPaper-root': {
            heightMin: 100,
            left: matches ? `calc(50% - 174px)` : 15,
          },
        }}
        >
        <Box display="flex" flexDirection="column" alignContent={'center'} justifyContent={'space-evenly'} height={160}>

          <TextField label="Название" variant="standard" value={addPlayListName} onChange={(e:any) => { setAddPlayListName(e.target.value)}}/>

          <FormControlLabel
            value="Онлайн доступ"
            control={<Checkbox 
              checked={checkedOnlineAccess}
              onChange={handleChangecheckedOnlineAccess}
            />}
            label="Онлайн доступ"
            labelPlacement="end"
          />

          <Button onClick={()=>{

            if(addPlayListName.length < 1){
              return
            }

            const id = uuidv4()

            setPlayLists({...playLists, lists: [...playLists.lists,
              {
                id: id,
                name: addPlayListName,
                online: checkedOnlineAccess,
                tracks: [],
              }
            ]})

            setAddPlayListName('')

            setAddPlayListPopup(false)
          }}>

            Добавить

          </Button>

        </Box>
      </SwipeableDrawer>




      <SwipeableDrawer
        anchor={`bottom`}
        open={playLists.delete}
        disableAutoFocus
        disableEnforceFocus
        onClose={()=>{

          setPlayLists({...playLists, delete: false})
        }}
        onOpen={()=>{
          
          setPlayLists({...playLists, delete: true})
        }}
        sx={{
          'justify-content': 'center','display': 'flex', 'align-items': 'center',
          '& .MuiPaper-root': {

            position: 'absolute',

            border: '0px solid #fd0000 !important',
            backgroundColor: 'rgba(255, 255, 255, .7)',
            backdropFilter: "blur(10px)",

            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,

            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,

            borderColor: 'none',

            padding: '25px',

            height: 80,

            width: 'calc( 100vw - 80px)',
            left: matches ? `calc(50% - 174px)` : 15,

            bottom: '15px',
          },
        }}
        >
        <Box display="flex" flexDirection="column" alignContent={'center'} justifyContent={'space-evenly'} height={140}>

          <Typography variant="body2" sx={{mb: 1.5}}>Вы действительно хотите удалить?</Typography>
          
          <Button onClick={()=>{

            setPlayLists({...playLists, lists: playLists.lists.filter(( list:ListsI ) =>  list.id !== playLists.open ), open: 'default', delete: false})
          }}>

            Да

          </Button>

        </Box>

      </SwipeableDrawer>

      <Stack ref={containerRef} direction="row" spacing={1}
        sx={{
          overflowX: 'scroll',
          maxWidth: 340,
          pb: 1, 
          pr: 1,
          scrollBehavior: 'smooth',
          scrollSnapStop: 'always',
          
          'mask-image': 'linear-gradient(80deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),rgba(0, 0, 0, 1), transparent 88%);',
          '-webkit-mask-image': 'linear-gradient(80deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),rgba(0, 0, 0, 1), transparent 100%);',
          
          '::-webkit-scrollbar': {
            width: 0,
          },
        }}>

          {playLists.lists.map((item:any, index:number) => {
            
            return <Chip
              key={index}
              label={item.name}
              variant={ playLists.open === item.id ? 'filled' : 'outlined'}
              onClick={()=>{
                setOpenList(item.id)
              }}
            />
          })}

          <Chip
            label="Добавить плейлист"
            onClick={handleAdd}
            variant="filled"
            color="primary" 
          />

        </Stack>

      </Box>
  )
}

export default PlayLists
