import { atom, selector } from "recoil"

export const currentMoreStateAtom = atom({
  key: "currentMoreStateAtom",
  default: null,
})

export const currentMoreStateSelector = selector({
  key: "currentMoreStateSelector",
  get: ({ get }) => {
    const currentMore = get(currentMoreStateAtom)
    return currentMore
  }
})

