import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const userRandomPauseStateAtom = atom({
  key: "userRandomPauseStateAtom",
  default: {
    status: true,
    min: 1,
    max: 10,
  },
  effects_UNSTABLE: [persistAtom],

})

export const userRandomPauseStateSelector = selector({
  key: "userRandomPauseStateSelector",
  get: ({ get }) => {
    const userRandomPause = get(userRandomPauseStateAtom)
    return userRandomPause
  }
})

