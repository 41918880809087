import { FC, useEffect , useState } from 'react'

import { useRecoilState, } from 'recoil'

import { ConnectingToDeviceQrReaderStateAtom } from '@state/ConnectingToDeviceQrReader'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'

import Box from '@mui/material/Box'

import useMediaQuery from '@mui/material/useMediaQuery'

import { QrReader } from 'react-qr-reader'

interface I {}

const ConnectingToDeviceQrReader: FC<I> = () => {
  
  const [ connectingToDeviceQrReader, setConnectingToDeviceQrReader ] = useRecoilState(ConnectingToDeviceQrReaderStateAtom)

  const [selected, setSelected] = useState<string>("environment")
  const [startScan, setStartScan] = useState<boolean>(false)
  const [loadingScan, setLoadingScan] = useState<boolean>(false)
  const [data, setData] = useState<string>("")

  const handleСancel = async () => {

    setStartScan(false)
    setConnectingToDeviceQrReader(false)
  }

  const handleScan = async (scanData:any) => {
    setLoadingScan(true);
    console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      setData(scanData);
      setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
    }
  };
  const handleError = (err:any) => {
    console.error(err);
  }

  const constraints =
  selected === 'user'
    ? { facingMode: { exact: 'user' } }
    : { facingMode: { exact: 'environment' } }

  const matches = useMediaQuery('(min-width:370px)')

  useEffect(() => {
    if(connectingToDeviceQrReader&&!startScan){
      setData('')
      setStartScan(true)
    }
  }, [connectingToDeviceQrReader])

  useEffect(() => {
    if(data !== ''){
      handleСancel()
    }
  }, [data])
  

  return (
    <Box display={'flex'} justifyContent="space-between" alignItems="center" sx={{ mt: 1, mb: 1  }}>

      <SwipeableDrawer
      anchor={`bottom`}
      open={connectingToDeviceQrReader}
      disableAutoFocus
      disableEnforceFocus
      
      onClose={handleСancel}
      onOpen={()=>{setConnectingToDeviceQrReader(true)}}
      sx={{
        'justify-content': 'center','display': 'flex', 'align-items': 'center',
        '& .MuiPaper-root': {

          position: 'absolute',

          border: '0px solid #fd0000 !important',
          backgroundColor: 'rgba(255, 255, 255, .7)',
          backdropFilter: "blur(10px)",

          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,

          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,

          borderColor: 'none',

          padding: 0,

          width: 'calc( 100vw - 80px)',
          left: matches ? `calc(50% - 150px)` : 15,

          bottom: '15px',
        },
      }}
      >
        {/*
          <button
            onClick={() => {
              setStartScan(!startScan);
            }}
          >
            {startScan ? "Stop Scan" : "Start Scan"}
          </button>
        */}
        {startScan && (
          <>

            {/*
              <select onChange={(e) => setSelected(e.target.value)}>
                <option value={"environment"}>Back Camera</option>
                <option value={"user"}>Front Camera</option>
              </select>
            */}

            <QrReader

              onResult={(result:any, error:any) => {
                if (!!result) {
                  setData(result?.text);
                }

                if (!!error) {
                  console.info(error);
                }
              }}
              constraints={{
                facingMode: "environment"
              }}
            />
          </>
        )}
        {/*
        {loadingScan && <p>Loading</p>}
        {data !== "" && <p>{data}</p>}
        */}

      </SwipeableDrawer>

    </Box>
  )
}

export default ConnectingToDeviceQrReader
