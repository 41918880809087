import { FC, useContext, useState } from 'react'

import { useRecoilState, } from 'recoil'

import { deviceStateAtom, deviceI } from '@state/device'
import { connectingToDeviceStateAtom } from '@state/connectingToDevice'
import { ConnectingToDeviceQrReaderStateAtom } from '@state/ConnectingToDeviceQrReader'

import { SocketContext } from '@context/socket'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import useMediaQuery from '@mui/material/useMediaQuery'

import { useWebSocket } from '@hooks/useWebSocket'

import HashToEmoji from "@components/HashToEmoji"

import QRCode from "react-qr-code"

const ConnectingToDevice: FC<any> = () => {

  const [ connectingToDevice, setConnectingToDevice ] = useRecoilState(connectingToDeviceStateAtom)
  const [ connectingToDeviceQrReader, setConnectingToDeviceQrReader ] = useRecoilState(ConnectingToDeviceQrReaderStateAtom)
  
  const [ device, setDevice ] = useRecoilState<deviceI>(deviceStateAtom)
  
  const { setRoom } = useWebSocket()

  const socket = useContext(SocketContext)

  const [ socketRoom, setSocketRoom ] = useState<string>()

  const matches = useMediaQuery('(min-width:370px)')

  return (
    <Box sx={{ maxHeight: '100vh' }}>

      <SwipeableDrawer
        anchor={`bottom`}
        open={connectingToDevice}
        disableAutoFocus
        disableEnforceFocus
        
        onClose={()=>{setConnectingToDevice(false)}}
        onOpen={()=>{setConnectingToDevice(true)}}
        sx={{
          'justify-content': 'center','display': 'flex', 'align-items': 'center',
          '& .MuiPaper-root': {

            position: 'absolute',

            border: '0px solid #fd0000 !important',
            backgroundColor: 'rgba(255, 255, 255, .7)',
            backdropFilter: "blur(10px)",

            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,

            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,

            borderColor: 'none',

            padding: '25px',

            width: 'calc( 100vw - 80px)',
            left: matches ? `calc(50% - 174px)` : 15,

            bottom: '15px',
          },
        }}
      >

        <Box  flexDirection="column" alignContent={'center'} justifyContent={'center'}>

          { device.uuid ?
            <>
              <Typography variant='tinyText'>Ваш ид: {device.uuid}</Typography>

              <Box display={'grid'} flexDirection="column" alignContent={'center'} justifyContent={'center'} sx={{mt: 2, width: '100%'}}>
                <QRCode value={device.uuid.toString()} />
              </Box>
            </>
            :
            <>
              uuid: { device.uuid }
            </>
          }
        
        </Box>

        <Button onClick={()=>{
            setConnectingToDevice(!connectingToDevice)

            setTimeout(() => {

              setConnectingToDeviceQrReader(!connectingToDeviceQrReader)
            }, 250)
          }}
          sx={{mt: 2}}
        > 
        
          Подключиться к устройству
        
        </Button>
        
        <Stack spacing={2} direction="column" alignItems="center" sx={{mt: 3}}>

          <Typography variant='tinyText'>Или введите ид в ручную</Typography>
        </Stack>
        
        <Box display="flex" flexDirection="column" alignContent={'center'} justifyContent={'space-evenly'} height={120}>

          <TextField  label="Или введите ид" variant="standard" value={socketRoom} onChange={(e:any) => { setSocketRoom(e.target.value)}}/>

          <Button onClick={()=>{ setRoom(socketRoom) }}> Ручное подключение </Button>

        </Box>        
        
      </SwipeableDrawer>

    </Box>
  )
}

export default ConnectingToDevice
