
import { FC, useEffect,useState } from 'react'
import axios from "axios"

import Login from "@components/Auth/Login"
import Registration from "@components/Auth/Registration"

const backendUrl = process.env.REACT_APP_BACKEND_URL

interface IAuth {}

const Auth: FC<IAuth> = () => {

  const [auth, setAuth] = useState<any>()
  
  useEffect(()=>{
    if(window.location.pathname === '/auth/google/callback'){

      const search = window.location.search

      axios({
        method: 'GET',
        url: `${backendUrl}/api/auth/google/callback?${search}`,
      })
        .then((res) => {
          console.log(res.data)
          console.log(res.data)
          console.log(res.data)
          setAuth(res.data)
        })
        .catch((error)=>{
          console.log(error)
        })
      }
  }, [])

  return (
    <>
      <Login/>
      <Registration/>
    </>
  )
}

export default Auth
