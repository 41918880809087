import React, { FC, useEffect,useState } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton';

import LoginIcon from '@mui/icons-material/Login'

import { useRecoilState, useSetRecoilState } from 'recoil'
import { jwtStateAtom, jwtI } from '@state/jwt'
import { registrationStateAtom } from '@state/registration'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import useMediaQuery from '@mui/material/useMediaQuery'

import axios from "axios"

const backendUrl = process.env.REACT_APP_BACKEND_URL

const initialUser = { password: "", confirmationPassword: "", identifier: "" }

interface IRegistration {}

const Registration: FC<IRegistration> = () => {

  const [registration, setRegistration] = useRecoilState<boolean>(registrationStateAtom)

  const [loading, setLoading] = useState<boolean>(false)

  const [user, setUser] = useState<any>(initialUser)

  const setJwt = useSetRecoilState<jwtI>(jwtStateAtom)

  const handleChange = (target:any) => {
    
    const { name, value } = target

    setUser((currentUser:any) => ({
      ...currentUser,
      [name]: value,
    }))
  }

  const handleRegistration = async () => {

    setLoading(true)

    const url = `${backendUrl}/api/auth/local/register`

    try {
      if (user.identifier && user.password  && user.confirmationPassword && (user.password  === user.confirmationPassword) ) {

        const { data } = await axios.post(url, user)
        if (data.jwt) {

          console.log(data)

          setLoading(false)

          //setJwt({token: data.jwt})
          //setLogin(false)
        }
      }else{
        setTimeout(()=>{
          setLoading(false)

        }, 3000)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const matches = useMediaQuery('(min-width:370px)')

  return (
    <>

    <SwipeableDrawer
      anchor={`bottom`}
      open={registration}
      disableAutoFocus
      disableEnforceFocus
      
      onClose={()=>{setRegistration(false)}}
      onOpen={()=>{setRegistration(true)}}
      sx={{
        'justify-content': 'center','display': 'flex', 'align-items': 'center',
        '& .MuiPaper-root': {

          position: 'absolute',

          border: '0px solid #fd0000 !important',
          backgroundColor: 'rgba(255, 255, 255, .7)',
          backdropFilter: "blur(10px)",

          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,

          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,

          borderColor: 'none',

          padding: '25px',

          width: 'calc( 100vw - 80px)',
          left: matches ? `calc(50% - 174px)` : 15,

          bottom: '15px',
        },
      }}
      >
        <Typography variant="h5" sx={{mb: 2.5, userSelect: 'none'}}>Создание аккаунта</Typography>

        <TextField
          type="email"
          name="identifier"
          value={user.identifier}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event.target)
          }}
          label="Емайл"
          variant="standard"
          fullWidth
          sx={{mb: 1.5}}
        />

        <TextField
          type="password"
          name="password"
          value={user.password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event.target)
          }}
          label="Пароль"
          variant="standard"
          fullWidth
          sx={{mb: 1.5}}
        />
        
        <TextField
          type="password"
          name="confirmationPassword"
          value={user.confirmationPassword}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event.target)
          }}
          label="Подтверждение пароль"
          variant="standard"
          fullWidth
          sx={{mb: 3}}
        />

        <LoadingButton
          onClick={handleRegistration}
          loading={loading}
          variant="outlined"
          fullWidth
          sx={{mb: 1}}
          endIcon={<LoginIcon />}
          loadingPosition="end"
        >
          { !loading && <>Создать аккаунт</> }
        </LoadingButton>
        
      </SwipeableDrawer>
    </>
  )
}

export default Registration
