import { FC } from 'react'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import { useRecoilState } from 'recoil'
import { backdropStateAtom } from '@state/backdrop'

const BackDrop: FC = () => {

  const handleClose = () => {
    setBackdrop(false)
  }

  const [dackdrop, setBackdrop] = useRecoilState<any>(backdropStateAtom)

  return (
    <>
      <Backdrop
        onClick={handleClose}
        sx={{ color: '#fff', zIndex: 900 }}
        open={dackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default BackDrop
