import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const volumeStateAtom = atom({
  key: "volumeStateAtom",
  default: 80,
  effects_UNSTABLE: [persistAtom],
})

export const volumeStateSelector = selector({
  key: "volumeStateSelector",
  get: ({ get }) => {
    const volume = get(volumeStateAtom)
    return volume
  }
})

