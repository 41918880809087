import { FC, useContext, useState, memo } from 'react'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { playListsStateAtom, playListsI, ListsI } from '@state/playLists'
import { currentTimeStateAtom } from '@state/currentTime'

import { durationStateAtom } from '@state/duration'

import { currentTrackStateAtom } from '@state/currentTrack'
import { trackIndexStateAtom } from '@state/trackIndex'
import { isPlayingStateAtom } from '@state/isPlaying'
import { randomPauseStateAtom } from '@state/randomPause'

import { playPlayListIdStateAtom } from '@state/playPlayListId'

import { moreStateAtom } from '@state/more'
import { currentMoreStateAtom } from '@state/currentMore'

import { soundContext } from '@context/sound'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress';

import PauseRounded from '@mui/icons-material/PauseRounded'

import MusicNoteIcon from '@mui/icons-material/MusicNote'

import PlayCircleIcon from '@mui/icons-material/PlayCircle'

import AddTrackToPlayList from '@components/AddTrackToPlayList'

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import DeleteIcon from '@mui/icons-material/Delete'

import CoverImage from '@components/CoverImage'

import { Remove } from '@components/File'

import { usePlayForIndex } from '@hooks/usePlayForIndex'

import blue from '@mui/material/colors/grey'

import { useNext } from "@hooks/useNext"


import { keyframes } from '@mui/system'

const spin = keyframes`
  from {
    transform: translate(75%);
  }
  to {
    transform: translate(-100%);
  }
`
const More: FC<any> = () => {
  
  const [ play ] = usePlayForIndex()

  const [ Next ] = useNext()

  const sound = useContext(soundContext)
  
  const trackIndex = useRecoilValue<any>(trackIndexStateAtom)

  const currentMore = useRecoilValue<any>(currentMoreStateAtom)

  const randomPause = useRecoilValue<any>(randomPauseStateAtom)

  const playPlayListId = useRecoilValue<any>(playPlayListIdStateAtom)  

  const setTrackIndex = useSetRecoilState<any>(trackIndexStateAtom)

  const setCurrentTime = useSetRecoilState<number>(currentTimeStateAtom)

  const setDuration = useSetRecoilState<number>(durationStateAtom)

  const [ addTrackToPlayList, setAddTrackToPlayList ] = useState<boolean>(false)

  const [ currentTrack, setCurrentTrack ] = useRecoilState<any>(currentTrackStateAtom)

  const [ isPlaying, setIsPlaying ] = useRecoilState<boolean>(isPlayingStateAtom)

  const [ more, setMore ]  = useRecoilState<boolean>(moreStateAtom)

    const [ loading, setLoading ] = useState<boolean>(false)

  let item:any = currentMore

  let index: number = 0

  let playListsKey: any
  let playListsId: string = ''

  const [ playLists, setPlayLists ] = useRecoilState<playListsI>(playListsStateAtom)

  for(let key in playLists.lists){
    if(playLists.lists[key].id === playLists.open){
      
      playListsId = playLists.lists[key].id
      const tracks = playLists.lists[key].tracks

      playListsKey = key

      for (let i = 0; i < tracks.length; i++) {

        if( tracks[i].id === currentMore?.id){
          //item = tracks[i]
          index = i
          break;
        }
      }
      break;
    }
  }

  const matches = useMediaQuery('(min-width:370px)')

  return (
      <>

        <AddTrackToPlayList track={currentMore} open={addTrackToPlayList} setOpen={setAddTrackToPlayList}/>

        <SwipeableDrawer
          anchor={`bottom`}
          open={more}
          disableAutoFocus
          disableEnforceFocus
          
          onClose={()=>{setMore(false)}}
          onOpen={()=>{setMore(true)}}
          sx={{
            'justify-content': 'center','display': 'flex', 'align-items': 'center',
            '& .MuiPaper-root': {
              left: matches ? `calc(50% - 174px)` : 15,
            },
          }}
        >

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CoverImage>
                <img
                  alt=""
                  src="/images/logo.png"
                />
              </CoverImage>
              <Box sx={{ ml: 1.5, minWidth: 0 }}>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                  <MusicNoteIcon sx={{ 
                      color:  randomPause && isPlaying ? blue[600] : isPlaying ? blue[600] : null 
                    }}/>

                  <Tooltip title={currentMore?.name} placement="bottom">

                    <Box sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: '208px',
                      maskImage: item?.name.length > 10 ? isPlaying ? 'linear-gradient(-80deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),rgba(0, 0, 0, 1), transparent 88%);' : 'unset' : 'unset',
                      '::-webkit-scrollbar': {
                        width: 0,
                      },
                    }}>

                      <Typography sx={{
                        display: 'inline-block',
                        animation: item?.name.length > 10 ? `${spin} 5s linear infinite` : 'unset',
                      }} variant="caption" color="text.secondary" fontSize={18} fontWeight={900}>

                        {item?.name}
                      </Typography>

                    </Box>

                  </Tooltip>

                </Stack>

                <Typography noWrap letterSpacing={-0.25} sx={{ maxWidth: 250, }}>

                  author: {item?.author & item?.author }
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mb: 2}}/>

          <Box>

            { loading ?

              <IconButton
                aria-label="play"
                >
                <CircularProgress color="inherit" size={24}/>
              </IconButton>
            :

              <IconButton
              aria-label="play"
              onClick={async()=>{

                if( trackIndex === index && playPlayListId === playListsId ){
                  setIsPlaying(!isPlaying) 
                  return;
                }

                setLoading(true)

                setTimeout(async () => {

                  await play(playListsKey, index)
                  setLoading(false)
                }, 500)
              }}>
                    
                { playPlayListId !== playListsId ? (
                  <PlayCircleIcon/>
                ) : (
                  <>
                    { trackIndex !== index ? (
                      <PlayCircleIcon/>
                    ) : (
                      <>
                        {!isPlaying ? (
                          <PlayCircleIcon/>
                        ) : (
                          <PauseRounded/>
                        )}
                      </>
                    )}
                  </>
                )}

              </IconButton>
            }

            <Typography variant="tinyText">Воспроизвести</Typography>

          </Box>
          
          <Box>
            <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: 1, px: 1, my: 1, ':hover': { transform: 'scale(1.025)', transition: '300ms', cursor: 'pointer' } }}
            onClick={()=>{
                
                setMore(false)
                setAddTrackToPlayList(true)
              }}>

              <PlaylistAddIcon/>
              <Typography variant="tinyText">Добавить в плейлист</Typography>
            </Stack>
          </Box>
          
          <Box>
            <Stack spacing={2} direction="row" alignItems="center" 
              sx={{ mb: 1, px: 1, my: 1, ':hover': { transform: 'scale(1.025)', transition: '300ms', cursor: 'pointer' } }}
              onClick={()=>{

                if(currentTrack){
                  if(currentTrack.id === item?.id){

                    let countTracks = 0

                    for(const list of playLists.lists) {

                      if ( list.id === playLists.open ) {

                        countTracks = list.tracks.length
                      }
                    }
                    
                    if( isPlaying && countTracks > 1){
                      
                      Next()
                    }else{

                      if(sound.current){

                        sound.current.unload()
                      }

                      setTrackIndex(null)
                      setCurrentTrack(null)

                      setCurrentTime(0)
                      setDuration(0)

                      if( isPlaying ){

                        setIsPlaying(false)
                      }
                    }
                  }
                }

                let updatePlayLists:any = playLists.lists

                if( item?.original === null ){

                  Remove(item)

                  updatePlayLists = updatePlayLists.map(( list:ListsI ) => {

                    return {...list, tracks: [ ...list.tracks.filter(( tracks:ListsI ) => tracks.id !== item?.id ) ]}
                  })
                }else{

                  updatePlayLists = updatePlayLists.map(( list:ListsI ) => {

                    if ( list.id === playLists.open ) {

                        return {...list, tracks: [ ...list.tracks.filter(( tracks:ListsI ) => tracks.id !== item?.id ) ]}
                    }
                    return list
                  })
                }

                setPlayLists({...playLists, lists: updatePlayLists})

                setMore(false)
              }}>
                
              <DeleteIcon/>
              <Typography variant="tinyText">Удалить</Typography>
            </Stack>
          </Box>
      </SwipeableDrawer>
    </>
  )
}

export default memo(More)