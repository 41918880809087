import { FC, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import StarIcon from '@mui/icons-material/StarBorder'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

//import { serverSideTranslations } from "next-i18next/serverSideTranslations"

//import { useTranslation } from "next-i18next"

import { FormattedNumber } from 'react-intl'

import prices from '@data/prices.json'

interface IPrices {
  action: any
}

const Prices: FC<IPrices> = ({action}) => {

  //const { t } = useTranslation("prices")
  //const prices = t<string, itemProps[]>('prices', { returnObjects: true })

  return (
          <Container component="main" sx={{ minHeight:280}}>
            <Grid container spacing={1} display="flex" alignItems="center">
              {prices.map((service:any) => (
                <Grid
                  item
                  key={service.title + service.subheader}
                  xs={12}
                  md={12}
                >
                  <Card
                  sx={{
                    backgroundColor: 'rgba(0,0,0, 0.1) !important',
                    color: 'rgb(255, 255, 255)',
                    boxShadow: 'unset',
                    borderRadius: 4,
                    padding: 0,
                  }}>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'baseline',
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          borderRadius: 2,
                          color: 'rgb(255, 255, 255)',

                        }}
                      >

                        <Typography variant="h4" sx={{
                          marginLeft: 0.5
                        }}>

                          <FormattedNumber value={service.price} style="currency" currency="RUB" /> / мес.
                        </Typography>
                        
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'baseline',
                          mb: 2,
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          borderRadius: 2,
                          color: 'rgb(255, 255, 255)',
                        }}
                      >

                        <Typography variant="h6" sx={{
                          marginLeft: 0.5
                        }}>

                          <FormattedNumber value={service.priceYear} style="currency" currency="RUB" /> / год
                        </Typography>
                      
                      </Box>

                      <ul style={{ marginLeft: -40, maxHeight: 410, overflow: 'auto' }}>
                        {service.description.map((line:any) => (
                          <Typography
                            component="li"
                            variant="tinyText"
                            align="center"
                            key={line}
                            sx={{
                              fontSize: 12,
                              listStyleType: 'none',
                              marginTop: 0.5,
                            }}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>

                    <CardActions>

                      <Button
                        fullWidth
                        variant='outlined' 
                        sx={{
                          color: 'rgb(255, 255, 255)',
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          borderRadius: 3,
                        }}
                        onClick={action}
                      >
                        {service.buttonText}
                      </Button>

                    </CardActions>

                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
  )
}

export default Prices
