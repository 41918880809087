import { FC, useEffect  } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'

import { playListsStateAtom, playListsI, ListsI } from '@state/playLists'

import { moreStateAtom } from '@state/more'

import Typography from '@mui/material/Typography'

import useMediaQuery from '@mui/material/useMediaQuery'

import VirtualList from './VirtualList'

import More from '@components/PlayListShow/More'

const PlayListShow: FC<any> = () => {

  const [ playLists, setPlayLists ] = useRecoilState<playListsI>(playListsStateAtom)

  const more = useRecoilState<boolean>(moreStateAtom)
  
  const getLists = ( id:string ) => {

    const tracksList = playLists.lists.filter((element:ListsI) => element.id === id)

    if(tracksList.length>0){
      return tracksList[0].tracks
    }else{
      return []
    }
  }
    
  const reorderLists = ( items:any ) => {

    setPlayLists({...playLists, lists: playLists.lists.map((list:ListsI ) => {
      if ( list.id === playLists.open ) {
          return {...list, tracks: items}
      }
      return list
    })})
  }
  
  const matches670 = useMediaQuery('(max-height:670px)')
  const matches570 = useMediaQuery('(max-height:570px)')

  return (
    <>

      { more &&
        <More/>
      }

      { getLists(playLists.open).length > 0 ? (
          <VirtualList
          height={ matches670 ? matches570 ? 230 : 320 : 380 }
          itemSize={50}
          items={getLists(playLists.open)}
          reorderLists={reorderLists}
          />
      ):(
        <Typography variant="tinyText" sx={{width: '100%', display: 'block', textAlign: 'center', pt: 5, pb: 5}}>Плейлист пуст</Typography>
      )}
    </> 
  )
}

export default PlayListShow
