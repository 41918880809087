import { FC, useEffect, useState, useRef } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'
import { autoPlayingStateAtom } from '@state/autoPlaying'
import { isPlayingStateAtom } from '@state/isPlaying'

import { onlyAtPermittedTimesStateAtom } from '@state/onlyAtPermittedTimes'

interface IOnlyAtPermittedTimes {}

const OnlyAtPermittedTimes: FC<IOnlyAtPermittedTimes> = () => {

  const [isPlaying, setIsPlaying] = useRecoilState<boolean>(isPlayingStateAtom)

  const autoPlaying = useRecoilValue<boolean>(autoPlayingStateAtom)
  const onlyAtPermittedTimes = useRecoilValue<any>(onlyAtPermittedTimesStateAtom)

  const setIntervalRef = useRef<any>(null)
  const [interval, setinterval] = useState<boolean>(false)

  useEffect(() => {

    if(interval){

      setinterval(false)

      if(!autoPlaying) return;

      const now = new Date()

      if( now.getHours() >= onlyAtPermittedTimes.night.from || now.getHours() <= onlyAtPermittedTimes.night.to ){
        
        if( isPlaying && onlyAtPermittedTimes.status ){
          setIsPlaying(false)
        }
      }else

      if( now.getHours() >= onlyAtPermittedTimes.day.from && now.getHours() <= onlyAtPermittedTimes.day.to ){

        if( isPlaying && onlyAtPermittedTimes.status ){
          setIsPlaying(false)
        }
      }else{

        if(!isPlaying){
          setIsPlaying(true)
        }
      }
    }
    
  },[interval, onlyAtPermittedTimes, autoPlaying, isPlaying, setIsPlaying])
  
  useEffect(() => {

    setIntervalRef.current = setInterval(() => {

      setinterval(true)
    }, 1000)

    return () => clearInterval(setIntervalRef.current)
  }, [])

  return (<></>)
}
export default OnlyAtPermittedTimes
