
import isElectron from "@utils/electron/is"
import isCordova from "@utils/cordova/is"

//import uuidElectron from "@utils/electron/uuid"
import uuidCordova from "@utils/cordova/uuid"

import { v4 as uuidv4 } from 'uuid'

const uuid = () => {

  if( isElectron() ){

    //return uuidElectron()
  }else 
  if( isCordova() ){

    return uuidCordova()
  }

  const uuid = uuidv4()
  
  return uuid
}

export default uuid
