import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

import { playListsI, ListsI, TrackI } from "./types"

const { persistAtom } = recoilPersist()

export const playListsStateAtom = atom<playListsI>({
  key: "playListsStateAtom",
  default: {
    open: 'default',
    delete: false,
    lists: [
      {
        id: 'default',
        name: 'Мои треки',
        online: true,
        tracks: []
      }
    ],
  },
  effects_UNSTABLE: [persistAtom],
})

export const playListsStateSelector = selector<playListsI>({
  key: "playListsStateSelector",
  get: ({ get }) => {
    const playLists = get(playListsStateAtom)
    return playLists
  }
})


export type { playListsI, ListsI, TrackI }
