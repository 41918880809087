import { createContext } from "react"

import { io, Socket } from "socket.io-client"


//const url:any = process.env.REACT_APP_WEBSOCKET

//export const socket: Socket = io('https://socket.xn--80aiclyhubf.xn--p1ai', {
export const socket: Socket = io('wss://antineighbors.ru', {
    path: '/ws',
    secure: true, 
    transports: ['websocket', 'polling'],
    reconnectionAttempts: 30,
    reconnectionDelay: 2000,
    reconnection: true,
    upgrade: true,
})

export const SocketContext = createContext<Socket | null>(null)



