
import React, { FC, useEffect, useState, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Howl } from 'howler'

import { useRecoilState, useSetRecoilState } from 'recoil'
import { backdropStateAtom } from '@state/backdrop'

import { playListsStateAtom, playListsI, ListsI, TrackI } from '@state/playLists'

import PouchDB from 'pouchdb'
const db:PouchDB.Database<{}> = new PouchDB('dbFiles')

export const useFileAdd = () => {

  let playListsKey: any

  const [ playLists, setPlayLists ] = useRecoilState<playListsI>(playListsStateAtom)

  for(let key in playLists.lists){
    if(playLists.lists[key].id === playLists.open){
      
      playListsKey = key

      break
    }
  }

  const setBackdrop = useSetRecoilState<any>(backdropStateAtom)

  const handleClose = () => {
    setBackdrop(false)
  }
  const handleOpen = () => {
    setBackdrop(true)
  }

  const readFileData = (file: File) => {
    return new Promise<Blob>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(new Blob([reader.result as ArrayBuffer], { type: file.type }))
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const fileReaderData = (file: File) => {
    return new Promise<any>(async (resolve, reject) => {

      const partsFileName = file.name.split('.')
      const originalFileNameNotExtension = partsFileName.slice(0, partsFileName.length - 1).join('.')

      const fileData = await readFileData(file)

      const reader = new FileReader()

      reader.onload = (event: any) => {

        const data = event.target?.result

        const newSound = new Howl({
          src: [data]
        })

        newSound.on("load", async() => {

          const duration:any = (newSound.duration()) | 0

          const id:string = uuidv4()

          db.put({
            _id: id,
            name: originalFileNameNotExtension,
            author: '',
            duration: duration,
            randomPause: {
              min: 10,
              max: 100
            },
            _attachments: {
              'audio': {
                content_type: fileData.type,
                data: fileData
              }
            }
          })

          let track:TrackI

          track = {
            id: id,
            original: null,
            name: originalFileNameNotExtension,
            author: null,
            duration: duration,
            randomPause: {
              min: 10,
              max: 60
            }
          }

          /*
          
          setPlayLists({
            ...playLists, 
            lists: playLists.lists.map(( list:ListsI ) => {
              if ( list.id === playLists.open ){
                return {
                  ...list, 
                  tracks: [
                    ...playLists.lists[playListsKey].tracks, 
                    track
                  ]
                }
              }
              return list
            })
          })

          */

          handleClose()

          resolve(track)

        })

        newSound.load()
      }

      reader.onerror = (error) => {

        handleClose()
        console.error("Failed file read:"+ JSON.stringify(error))
        reject(error)

      }

      reader.readAsDataURL(file)
    })
  }

  const FileChange = async (file:File) => {
    return new Promise<Blob>((resolve, reject) => {
      if (file) {
        try {
          handleOpen()
          resolve(fileReaderData(file))
        } catch (error) {
          reject(error)
        }
      }
    })
    
  }

  return [FileChange] as const;
}