import { FC, useRef, useEffect, useCallback } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import MusicNoteIcon from '@mui/icons-material/MusicNote'

import { blue } from '@mui/material/colors'

import { useRecoilValue, useSetRecoilState } from 'recoil'

import { isPlayingStateAtom } from '@state/isPlaying'
import { loopStateAtom } from '@state/loop'
import { randomPauseStateAtom } from '@state/randomPause'
import { randomVolumeStateAtom } from '@state/randomVolume'
import { volumeStateAtom } from '@state/volume'
import { userRandomPauseStateAtom } from '@state/userRandomPause'
import { userRandomVolumeStateAtom } from '@state/userRandomVolume'
import { currentTrackStateAtom } from '@state/currentTrack'
import { endTracksTriggerStateAtom } from '@state/endTracksTrigger'

import CoverImage from '@components/CoverImage'

import { useHowler } from '@hooks/useHowler'

import { usePrevious } from "@hooks/usePrevious"
import { useNext } from "@hooks/useNext"

import { keyframes } from '@mui/system'

const spin = keyframes`
  from {
    transform: translate(100%);
  }
  to {
    transform: translate(-100%);
  }
`
const getRandomNumber =(min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const DisplayTrack: FC<any> = () => {
  
  const { audio } = useHowler()

  const [Next] = useNext()

  const timerEndedRef = useRef<any>()

  const isPlaying = useRecoilValue<boolean>(isPlayingStateAtom)
  const isLoop = useRecoilValue<boolean>(loopStateAtom)
  const randomPause = useRecoilValue<boolean>(randomPauseStateAtom)
  const randomVolume = useRecoilValue<boolean>(randomVolumeStateAtom)
  const userRandomPause = useRecoilValue<any>(userRandomPauseStateAtom)
  const userRandomVolume = useRecoilValue<any>(userRandomVolumeStateAtom)

  const currentTrack = useRecoilValue<any>(currentTrackStateAtom)
  const endTracksTrigger = useRecoilValue<any>(endTracksTriggerStateAtom)

  const setVolume = useSetRecoilState<number>(volumeStateAtom)

  const ended = useCallback(() => {

    clearTimeout(timerEndedRef.current)

    if(isPlaying){
      
      if(!isLoop){

        if(randomVolume){

          let min = 50
          let max = 100

          if(userRandomVolume.status){
            if( userRandomVolume.max > userRandomVolume.min ){
              min = userRandomVolume.min
              max = userRandomVolume.max
            }
          }

          const volume = getRandomNumber( min, max )

          setVolume( volume ) 
        }
        
        if(!randomPause){
          Next()
        }else{
          
          let min = currentTrack.randomPause.min
          let max = currentTrack.randomPause.max

          if(userRandomPause.status){
            if( userRandomPause.max > userRandomPause.min ){
              min = userRandomPause.min
              max = userRandomPause.max
            }
          }

          const pause = getRandomNumber( min, max )

          timerEndedRef.current = setTimeout(()=>{
            Next()
          }, pause * 1000)
        }
      }else{

        if(randomVolume){

          let min = 50
          let max = 100

          if(userRandomVolume.status){
            if( userRandomVolume.max > userRandomVolume.min ){
              min = userRandomVolume.min
              max = userRandomVolume.max
            }
          }

          const volume = getRandomNumber( min, max )

          setVolume( volume ) 
        }

        if(!randomPause){
          audio.current.seek( 0 )
          audio.current.play()
        }else{
          
          let min = currentTrack.randomPause.min
          let max = currentTrack.randomPause.max

          if(userRandomPause.status){
            if( userRandomPause.max > userRandomPause.min ){
              min = userRandomPause.min
              max = userRandomPause.max
            }
          }

          const pause = getRandomNumber( min, max )

          timerEndedRef.current = setTimeout(()=>{
            audio.current.seek( 0 )
            audio.current.play()
          }, pause * 1000)
        }
      }
    }
  },[isPlaying, isLoop, randomPause, randomVolume, userRandomPause, userRandomVolume, timerEndedRef, setVolume, currentTrack, Next])


useEffect(() => {

  console.log('useEffect endTracksTrigger')
  console.log('useEffect endTracksTrigger')
  console.log(`useEffect endTracksTrigger #${endTracksTrigger}`)

  //ended()
}, [endTracksTrigger])


  return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CoverImage>
            <img
              alt=""
              src="/images/logo.png"
            />
          </CoverImage>
          <Box sx={{ ml: 1.5, minWidth: 0 }}>
            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
              <MusicNoteIcon sx={{ color:  randomPause && isPlaying ? blue[600] : isPlaying ? blue[600] : null }}/>
              <Tooltip title={ currentTrack ? currentTrack.name : '' } placement="bottom">
                <Box sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: '208px',
                  maskImage: currentTrack ? currentTrack.name.length > 14 ?  isPlaying ? 'linear-gradient(-80deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),rgba(0, 0, 0, 1), transparent 88%);' : 'unset' : 'unset' : '',
                  '::-webkit-scrollbar': {
                    width: 0,
                  },
                }}>
                  <Typography sx={{
                      display: 'inline-block',
                      animation:  currentTrack ? currentTrack.name.length > 14 ? isPlaying ? `${spin} 5s linear infinite` : 'unset' : 'unset' : '',
                    }} variant="caption" color="text.secondary" fontSize={18} fontWeight={900}>
                      { currentTrack ? currentTrack.name : ''}
                  </Typography>
                </Box>
              </Tooltip>
            </Stack>
            {currentTrack && currentTrack.author &&
              <Typography noWrap letterSpacing={-0.25} sx={{ maxWidth: 250, }}>
                author: { currentTrack ? currentTrack.author ? currentTrack.author : '---' : 'Нету аудио треков' }
              </Typography>
            }
          </Box>
        </Box>
      </Box>
  )
}

export default DisplayTrack
