
import { useSetRecoilState, useRecoilValue } from 'recoil'

import { currentTrackStateAtom } from '@state/currentTrack'
import { trackIndexStateAtom } from '@state/trackIndex'
import { playPlayListIdStateAtom } from '@state/playPlayListId'

import { playListsStateAtom, playListsI } from '@state/playLists'

import { Get } from '@components/File'

import { useHowler } from '@hooks/useHowler'

export const useSelectAudioOnOpenApp = () => {

  const { changeTrack } = useHowler()

  const setTrackIndex = useSetRecoilState<any>(trackIndexStateAtom)

  const setCurrentTrack = useSetRecoilState<any>(currentTrackStateAtom)

  const playLists = useRecoilValue<playListsI>(playListsStateAtom)

  const setPlayPlayListId = useSetRecoilState<string>(playPlayListIdStateAtom)
  
  const SelectAudioOnOpenApp = async (local?: boolean) => {

    return new Promise<boolean>(async(resolve, reject) => {
        try {

          let lastTrackIndex: number = 0

          let track: any = null

          for( let key in playLists.lists ){
            if( playLists.lists[key].id === playLists.open ){

              if( playLists.lists[key].tracks.length > 0){

                track = playLists.lists[key].tracks[0]

                setTrackIndex(lastTrackIndex)
                setCurrentTrack(playLists.lists[key].tracks[0])
                
                setPlayPlayListId(playLists.lists[key].id)

                const trackGetData: any = await Get(track)

                changeTrack({
                  src: [trackGetData],
                  format: ['mp3'],
                })

                break;
              }
            }
          }
        
          resolve(true)
          
        } catch (error) {
          reject(error)
        }
    })
    
  }

  return [SelectAudioOnOpenApp] as const;
}