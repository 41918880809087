import React, { FC, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'

import { RecoilRoot } from 'recoil'

import reportWebVitals from './reportWebVitals'

import ReactGA from "react-ga4";

import { SocketContext, socket } from '@context/socket'
import { soundContext } from '@context/sound'
import { audioContext } from '@context/audio'

import AudioPlayer from '@components/AudioPlayer'

import './index.css'

import { alpha , createTheme, ThemeProvider } from '@mui/material/styles'

import grey from '@mui/material/colors/grey'
import lightBlue from '@mui/material/colors/lightBlue'

import '@fontsource/raleway/300.css'
import '@fontsource/raleway/400.css'
import '@fontsource/raleway/500.css'
import '@fontsource/raleway/700.css'
import '@fontsource/raleway/800.css'
import '@fontsource/raleway/900.css'

import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'

ReactGA.initialize("G-WTKH7GTK1H")

const fontFamily = 'Raleway'

let colors:any = {
	color: grey[900],
  primary:  grey[900],
  secondary:   lightBlue[900],
	firstLetter: lightBlue[900],
}

colors.button = {
  background: `linear-gradient(90.3deg, ${colors.primary} -168.63%, ${colors.primary} 170.34%)`,
  color: 'rgba(255, 255, 255, 1)'

}

let theme = createTheme()
theme = createTheme(theme , {
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase': {
            color: grey[900],
            '&:hover': {
              backgroundColor: alpha(colors.primary, theme.palette.action.hoverOpacity),
            },
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: grey[900],
            '&:hover': {
              backgroundColor: alpha(grey[900], theme.palette.action.hoverOpacity),
            },
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: grey[700],
          },
          '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            '&:before, &:after': {
              content: '""',
              position: 'absolute',
              top: '40%',
              transform: 'translateY(-50%)',
              width: 12,
              height: 12,
            },
            '&:before': {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
              )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
              left: 14,
            },
          },
          '-webkit-user-select': 'none',
          '-moz-user-select': 'none',
          '-ms-user-select': 'none',
              'user-select': 'none',
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            border: '0px solid #fd0000 !important',
            backgroundColor: 'rgba(255, 255, 255, .7)',
            backdropFilter: "blur(10px)",
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderColor: 'none',
            maxWidth: 300,
            position: 'absolute',
            padding: '25px',
            overflowX: 'hidden',
            bottom: '15px',
            width: 'calc( 100vw - 80px)',

          },
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {

        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingLeft: 5,
        }
      }
    },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {

          background: 'RGB(255, 255, 255)',
          border: '2px solid #015F99',
          borderRadius: 8,
          
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            height: 20,
            border: '0px solid #909090',

            ':hover': {
              border: '0px solid #fd0000 !important',
              boxShadow: '-1px 1px 4px 4px rgba(219, 219, 219, 0.25);',

              borderRadius: '10px',
              borderColor: 'none',
            },
            ':focus-within': { border: '0px solid #fd0000 !important' }
          },
          '& .MuiOutlinedInput-root.Mui-disabled': {
            ':hover': {
              border: '0px solid #909090 !important',
              boxShadow: 'none'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {

        },
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
        }
      }
    },

    MuiInputBase: {
        styleOverrides: {
          root: {
            borderWidth: '26px',

            borderRadius: '5px',
            borderColor: 'none',
            border: 0,

            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            backdropFilter: "blur(10px)",

            padding: 2.5,
            paddingLeft: 10,

            ':hover': {
              borderRadius: '8px',
              borderColor: 'none',
              border: 0,
            },

            '& .MuiOutlinedInput-root': {
              borderRadius: '7px',
              height: 50,
              border: '1px solid #909090',
              ':hover': {
                border: '0px solid #fd0000 !important',
                boxShadow: '-1px 1px 4px 4px #FFEAEA'
              },
              ':focus-within': { border: '0px solid #fd0000 !important' }
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
              ':hover': {
                border: '0px solid #909090 !important',
                boxShadow: 'none'
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          },
        }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: '10px',
        },
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: '#e3abed',
          },
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {

          transition: '300ms',
          '-webkit-transition': '350ms !important', // fixed webkit
          borderRadius: 10,
          fontWeight: 600,
          color: colors.button.color,

          background: colors.button.background,
          "&:hover": {
            opacity: '0.9',
            scale: '1.025',
          },
          '-webkit-user-select': 'none',
          '-moz-user-select': 'none',
          '-ms-user-select': 'none',
              'user-select': 'none',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: colors.button.color,
          //background: colors.button.background,
          '&:disabled': {
            color: `${colors.button.color}  !important`,
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          maxWidth: 'unset',
        }
      }
    },    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: '300ms',
          '&:hover': {
            //opacity: 0.9,
            //scale: '1.05'
          }
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        ol: {
          transition: '300ms',
          flexWrap: 'nowrap',
        },
        li: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          cursor: 'default',
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 26,
          [theme.breakpoints.between("xs", "sm")]: {
            fontSize: 12,
          },
          [theme.breakpoints.between("sm", "md")]: {
            fontSize: 22,
          },
          [theme.breakpoints.between("lg", "xl")]: {
            fontSize: 28,
          },
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        root: {
        },
        badge: {
          height: 38,
          minWidth: 28,
          borderRadius: '50%' ,
          color: 'rgba(255, 255, 255, 1) !important',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
                'user-select': 'none',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
                'user-select': 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.color,
    },
    secondary: colors.secondary,
  },
  typography: {
    allVariants: {
      fontFamily: [fontFamily, 'Roboto', 'sans-serif'].join(','),
    },
    tinyText: {
      fontFamily: fontFamily,
      color: colors.color,
      fontSize: '0.75rem',
      opacity: 0.75,
      fontWeight: 500,
      letterSpacing: 0.2,
      '&::first-letter': {
        color: colors.firstLetter,
      },
    },
    h1: {
      fontFamily: fontFamily,
      color: colors.color,
      fontWeight: 600,
      textAlign: 'center',
      '&::first-letter': {
        color: colors.firstLetter,
      },
    },
    h2: {
      fontFamily: fontFamily,
      color: colors.color,
      fontWeight: 600,
      textAlign: 'center',
      '&::first-letter': {
        color: colors.firstLetter,
      },
    },
    h3: {
      fontFamily: fontFamily,
      color: colors.color,
      fontWeight: 600,
      textAlign: 'center',
      '&::first-letter': {
        color: colors.firstLetter,
      },
    },
    h4: {
      fontFamily: fontFamily,
      color: colors.color,
      fontWeight: 600,
      textAlign: 'center',
      '&::first-letter': {
        color: colors.firstLetter,
      },
    },
    h5: {
      fontFamily: fontFamily,
      color: colors.color,
      fontWeight: 600,
      textAlign: 'center',
      '&::first-letter': {
        color: colors.firstLetter,
      },
    },

    h6: {
      fontFamily: fontFamily,
      color: colors.color,
      fontWeight: 600,
      textAlign: 'center',
      '&::first-letter': {
        color: colors.firstLetter,
      },
    },


    body1: {
      fontFamily: fontFamily,
      color: colors.color,
      fontWeight: 400,
      fontSize: 18,
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: 14,
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.between("lg", "xl")]: {
        fontSize: 18,
      },
      lineHeight: 1,
      letterSpacing: 0.2,
      '&::first-letter': {
        color: colors.firstLetter,
      },
    },


    body2: {
      fontFamily: fontFamily,
      color: colors.color,
      fontWeight: 400,
      fontSize: 16,
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: 12,
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: 14,
      },
      [theme.breakpoints.between("lg", "xl")]: {
        fontSize: 16,
      },
      lineHeight: 1.2,
      letterSpacing: 0.2,
      '&::first-letter': {
        color: colors.firstLetter,
      },
    },
  },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const App: FC<any> = () => {

  const audio = useRef<any>()

  const audioNew = useRef<any>(null)

  useEffect(() => {

    const currentURL = window.location.href;

    // Проверяем, содержит ли URL ваш домен
    if (currentURL.includes('antineighbors.ru')) {
      // Приложение было открыто на вашем сайте
      // Выполняйте необходимые действия

    } else {
      throw "ban";
    }

  }, [])
  
  return  (
            //<React.StrictMode> ломает react-beautiful-dnd (DragDrop)
              <IntlProvider locale="ru" defaultLocale="en">
                <RecoilRoot>
                <audioContext.Provider value={ audioNew }>
                  <SocketContext.Provider value={socket}>
                    <ThemeProvider theme={theme}>
                      <soundContext.Provider value={{ ref: audio }}>
                        <AudioPlayer/>
                      </soundContext.Provider>
                    </ThemeProvider>
                    </SocketContext.Provider>
                    </audioContext.Provider>
                </RecoilRoot>
              </IntlProvider>
            //</React.StrictMode>
          )
}


const renderReactDom = () => {

	root.render(<App/>)
}

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    window.resolveLocalFileSystemURL(window.cordova.file.dataDirectory, () => {}, () => {});
    renderReactDom()
  }, false)
} else {
  renderReactDom()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
