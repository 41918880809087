import { FC, useState } from 'react'

import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { playListsStateAtom, playListsI } from '@state/playLists'
import { deviceStateAtom, deviceI } from '@state/device'
import { settingsStateAtom } from '@state/settings'
import { loginStateAtom } from '@state/login'
import { tariffsStateAtom } from '@state/tariffs'
import { connectingToDeviceStateAtom } from '@state/connectingToDevice'

import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import SettingsIcon from '@mui/icons-material/Settings'
import MergeTypeIcon from '@mui/icons-material/MergeType'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import HelpIcon from '@mui/icons-material/Help'
import requestFile from '@utils/cordova/files'
import LeakAddIcon from '@mui/icons-material/LeakAdd'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote'

import { Upload } from '@components/File'

const ListSpeedDial: FC = () => {
  
  const [ playLists, setPlayLists ] = useRecoilState<playListsI>(playListsStateAtom)
  
  const device = useRecoilValue<deviceI>(deviceStateAtom)

  const [open, setOpen] = useState(false)
  
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const setSettings = useSetRecoilState<boolean>(settingsStateAtom)
  const setLogin = useSetRecoilState<boolean>(loginStateAtom)
  const setTariffs = useSetRecoilState<boolean>(tariffsStateAtom)
  const setConnectingToDevice = useSetRecoilState<boolean>(connectingToDeviceStateAtom)
  
  const actions = []

  actions.push({ icon: <SettingsIcon />, name: 'Настройки', action: ()=>{

    setSettings(true)
    handleClose()
  }})


  actions.push({ icon: <HelpIcon />, name: 'Помощь', action: ()=>{

    handleClose()
  }})


  actions.push({ icon: <SubscriptionsIcon />, name: 'Подписка', action: ()=>{

    setTariffs(true)
    handleClose()
  }})
  
  actions.push({ icon: <LoginIcon />, name: 'Войти в аккаунт', action: ()=>{
    
    setLogin(true)
    handleClose()
  }})

  actions.push({ icon: <SettingsRemoteIcon />, name: `Подключение к устройству`, action: ()=>{

    setConnectingToDevice(true)
    handleClose()
  }})
  
  /*
    actions.push({ icon: <MergeTypeIcon />, name: `Тип устройства`, action: ()=>{

      setSettings(true)
      handleClose()
    }})
  */
  if( playLists.open !== 'default' ){
    
    actions.push({ icon: <DeleteForeverIcon />, name: 'Удалить плейлист', action: ()=>{

      setPlayLists({...playLists, delete: true})
      handleClose()
    }})
  }

  actions.push({ icon: <Upload />, name: 'Загрузить аудиофайл', action: ()=>{
    
    //requestFile()
    handleClose()
  }})

  return (
      <SpeedDial
        ariaLabel="ListSpeedDial"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          '& .MuiSpeedDialAction-staticTooltipLabel': {
            backgroundColor: 'rgba(255, 255, 255, .7)',
            backdropFilter: "blur(10px)",
            borderRadius: 10,
            width: 'max-content',
            textAlign: 'right',
            cursor: 'default',
            'overflow': 'hidden',
            'display': '-webkit-box',
            '-webkit-line-clamp': '1',
            '-webkit-box-orient': 'vertical',
            'text-overflow': 'ellipsis',
          }
        }}
        icon={<MoreVertIcon/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={()=>{
              action.action()
            }}
          />
        ))}
      </SpeedDial>
  )
}

export default ListSpeedDial