import { atom, selector } from "recoil"

export const playPlayListIdStateAtom = atom({
  key: "playPlayListIdStateAtom",
  default: '',
})

export const playPlayListIdStateSelector = selector({
  key: "playPlayListIdStateSelector",
  get: ({ get }) => {
    const playPlayListId = get(playPlayListIdStateAtom)
    return playPlayListId
  }
})

