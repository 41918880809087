import { useContext, useEffect} from 'react'

import { Howl, HowlOptions, Howler } from 'howler'

import { audioContext } from '@context/audio'

import { useSetRecoilState, useRecoilState } from 'recoil'

import { durationStateAtom } from '@state/duration'
import { endTracksTriggerStateAtom } from '@state/endTracksTrigger'
import { currentHowlerPlayIdStateAtom } from '@state/currentHowlerPlayId'

import { IAudio } from "./types"

export type { IAudio }

export const useHowler = (options: IAudio = {
  src: ['/assets/audio/Одиночный глухой стук.mp3'],
  format: ['mp3'],
}) => {

  const player = useContext(audioContext)

  const audio = player

  const setDuration = useSetRecoilState<number>(durationStateAtom)

  const setEndTracksTrigger = useSetRecoilState<any>(endTracksTriggerStateAtom)
  const [currentHowlerPlayId , setCurrentHowlerPlayId] = useRecoilState<any>(currentHowlerPlayIdStateAtom)
  
  const generateHowlerAudio = (audioProps: IAudio): void => {

    //@ts-ignore
    window.Howler._canPlayEvent = 'canplay' // fixes the "wait for file complete downloaded"


    if (player.current === null) {

      class ExtendedHowl extends Howl {
        _src: any
        _format: any
        preload: any
        autoplay: any
        loop: any
        html5: any
      
        constructor(options: any) {
          super(options)
          this._src = options.src
          this._format = options.format
          this.preload = options._preload
          this.autoplay = options._autoplay
          this.loop = options._loop
          this.html5 = options._html5
        }
      
        async changeAudio(o: { src: string | string[]; format: string | string[] }): Promise<void> {

          console.log('changeAudio')

          this.unload()
          this.stop()
          await this.initAudio(o)
        }
      
        private async initAudio(o: { src: string | string[]; format: string | string[] }): Promise<void> {

          console.log('initAudio')

          this._src = typeof o.src !== 'string' ? o.src : [o.src]
          this._format = typeof o.format !== 'string' ? o.format : [o.format]
          this.preload = true
          this.autoplay = true
          this.html5 = true
          this.loop = false
          await this.load()
        }
      }

      const howlOptions: HowlOptions = {
        src: audioProps.src,
        format: audioProps.format,
        html5: true,
      }

      player.current = new ExtendedHowl(howlOptions)

      player.current.on('load', function(){
        console.log("load load load ");  

        setDuration( audio.current.duration() )
      })
      console.log("set ONEND");  

      player.current.on('end', function(){

        console.log("ONEND");  

        setEndTracksTrigger(Date.now())
      })



      player.current.on('loaderror', () => {
        console.log("loaderror");  

        Howler.unload();
      });
  
      player.current.on('playerror', () => {
        console.log("playerror");  

        Howler.unload();
      });
      player.current.on('stop', () => {
        console.log("stop stop stop ");  

      });
  
      player.current.on('unlock', () => {
        console.log("unlock");  

      });

  
      player.current.on("end", () => {
        console.log("end");  

        //player.current.stop();
      })
    }
  }

  useEffect(() => {

    generateHowlerAudio(options)
  }, [])



  const play = () => {
    if(!player.current)return;

    console.log(`currentHowlerPlayId: ${currentHowlerPlayId}`)

    if(currentHowlerPlayId!==0){

      const play = player.current.play(currentHowlerPlayId)

      console.log(`play: ${play}`)
      setCurrentHowlerPlayId(play)
    }else{

    const play = player.current.play()

    console.log(`play: ${play}`)
    setCurrentHowlerPlayId(play)
    }
    

    try {
      
    } catch (error) {
      
    }
  }



  const stop = () =>  {
    if(!player.current)return; 

    //player.current.stop()

  }

  //const pause = () => player.current && player.current.pause()
  const pause = () => {
    if(!player.current)return; 
    if(currentHowlerPlayId!==0){

    //player.current.pause(currentHowlerPlayId)
    var currentPosition = audio.current.pos();

    console.log(`currentPosition: ${currentPosition}`)

    const play = player.current.pause(currentHowlerPlayId)

    console.log(`pause play: ${play}`)

    console.log('player.current._sounds[0]')
    console.log(player.current._sounds[0])

    
    console.log(play._sounds[0])

    console.log(play._sounds[0]._id)
    console.log(play._sounds[0]._seek)

    setCurrentHowlerPlayId(play._sounds[0]._id)

//    player.current._sounds[0]._paused = true



    }
  }


  

  const fade = (from: number, to: number, duration: number) => {

    if (player.current) {

      player.current.fade(from, to, duration)
    }
  }

  const changeTrack = async (newOptions: IAudio) => {

    if (player.current) {

      await player.current.unload()
    }

    await player.current.changeAudio(newOptions)
  }
  
  return { audio, play, stop, pause, fade, changeTrack }
}

export default useHowler