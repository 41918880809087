import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const loopStateAtom = atom({
  key: "loopStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],
})

export const loopStateSelector = selector({
  key: "loopStateSelector",
  get: ({ get }) => {
    const loop = get(loopStateAtom)
    return loop
  }
})

