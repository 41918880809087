import { FC, useEffect, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import SwipeableViews from 'react-swipeable-views'

import ListIcon from '@mui/icons-material/List'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import LeakAddIcon from '@mui/icons-material/LeakAdd'
import MoodIcon from '@mui/icons-material/Mood'

import { useRecoilState, useSetRecoilState } from 'recoil'
import { tariffsStateAtom } from '@state/tariffs'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import useMediaQuery from '@mui/material/useMediaQuery'

import Prices from '@components/Prices'

interface ITariffs {}

const Tariffs: FC<ITariffs> = () => {
  
  const [tariffs, setTariffs] = useRecoilState<boolean>(tariffsStateAtom)

  const matches = useMediaQuery('(min-width:370px)')

  const handleAction = () => {
    alert('handleAction')
    setTariffs(false)

    window.open('https://vl.ru', "_blank")?.focus() // тут открываем страницу оплаты яндекса
  }

  return (
    <SwipeableDrawer
      anchor={`bottom`}
      open={tariffs}
      disableAutoFocus
      disableEnforceFocus
      
      onClose={()=>{setTariffs(false)}}
      onOpen={()=>{setTariffs(true)}}
      sx={{
        'justify-content': 'center','display': 'flex', 'align-items': 'center',
        '& .MuiPaper-root': {
          left: matches ? `calc(50% - 174px)` : 15,
        },
      }}
      >

        <Prices action={handleAction}/>
      </SwipeableDrawer>
  )
}
export default Tariffs
