import { atom, selector } from "recoil"

export const connectingToDeviceStateAtom = atom({
  key: "connectingToDeviceStateAtom",
  default: false,
})

export const connectingToDeviceStateSelector = selector({
  key: "connectingToDeviceStateSelector",
  get: ({ get }) => {
    const connectingToDevice = get(connectingToDeviceStateAtom)
    return connectingToDevice
  }
})
