import { atom, selector } from "recoil"

export const currentTrackStateAtom = atom({
  key: "currentTrackStateAtom",
  default: null,
})

export const currentTrackStateSelector = selector({
  key: "currentTrackStateSelector",
  get: ({ get }) => {
    const currentTrack = get(currentTrackStateAtom)
    return currentTrack
  }
})

