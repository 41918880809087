import { FC, useEffect, useRef, ChangeEvent } from 'react'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

import { useRecoilState } from 'recoil'

import { playListsStateAtom, playListsI, ListsI } from '@state/playLists'

import { useFileAdd } from '@hooks/useFile'

import PouchDB from 'pouchdb'
const db:PouchDB.Database<{}> = new PouchDB('dbFiles')

const Upload: FC = () => {

  let playListsKey: any

  const [ playLists, setPlayLists ] = useRecoilState<playListsI>(playListsStateAtom)

  for(let key in playLists.lists){
    if(playLists.lists[key].id === playLists.open){
      
      playListsKey = key

      break
    }
  }

  const inputFileRef = useRef<HTMLInputElement>(null)
  
  const handleButtonClick = () => {

    inputFileRef.current?.click()
  }

  const [ FileChange ] = useFileAdd()

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {

    const file = event.target.files?.[0]

    if(undefined !== file){

      const track = await FileChange(file)

      if ( playLists.open === 'default' ){

        return setPlayLists({
          ...playLists, 
          lists: playLists.lists.map(( list:ListsI ) => {
  
            if ( list.id === 'default' ){
              return {
                ...list, 
                tracks: [
                  ...playLists.lists[playListsKey].tracks, 
                  track
                ]
              }
            }
            
            return list
          })
        })

      }

      setPlayLists({
        ...playLists, 
        lists: playLists.lists.map(( list:ListsI ) => {

          if ( list.id === 'default' ){
            return {
              ...list, 
              tracks: [
                ...playLists.lists[playListsKey].tracks, 
                track
              ]
            }
          }
          
          if ( list.id === playLists.open && playLists.open !== 'default' ){
            return {
              ...list, 
              tracks: [
                ...playLists.lists[playListsKey].tracks, 
                track
              ]
            }
          }

          return list
        })
      })

    }
  }

  useEffect(() => {
    /*
      db.allDocs().then(function (result) {
        return Promise.all(result.rows.map(function (row) {
          return db.remove(row.id, row.value.rev)
        }));
      }).then(function () {
        // done!
      }).catch(function (err) {
        // error!
      })
    */
    db.info().then(function (info) {
      console.log(info)
    })
    
  }, [])

  return (
    <>
      <input
        type="file"
        accept="audio/*" // Указываем принимаемые типы файлов - любые аудиофайлы
        ref={inputFileRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <CloudDownloadIcon onClick={handleButtonClick}>Выбрать музыку</CloudDownloadIcon>
    </>
  )
}

const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const dataURL = reader.result as string
      resolve(dataURL)
    };
    reader.onerror = () => {
      reject(new Error('Unable to convert Blob to Data URL'))
    }
    reader.readAsDataURL(blob)
  })
}

const Get = async (item:any) => {

  const blobOrBuffer = await db.getAttachment(item.id, 'audio')

  //@ts-ignore
  const dataURL = await blobToDataURL(blobOrBuffer)

  return dataURL
}

const Remove = (item:any) => {

  console.log(`remove id:${item.id}`)

  db.get(item.id).then(function(doc: any) {
    console.log(doc)
    return db.remove(doc._id, doc._rev);
   // return db.remove(doc)
  }).then(function (result) {
    console.log(`remove file for indexedDB id:${item.id}`)
    console.log(result)
  }).catch(function (err) {
    console.log(`err remove file for indexedDB id:${item.id}`)

    console.log(err)
  })
}

export { Upload, Get, Remove }