import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

import { jwtI } from "./types"

const { persistAtom } = recoilPersist()

export const jwtStateAtom = atom<jwtI>({
  key: "jwtStateAtom",
  default: {
    token: null
  },
  effects_UNSTABLE: [persistAtom],
})

export const jwtStateSelector = selector<jwtI>({
  key: "jwtStateSelector",
  get: ({ get }) => {
    const jwt = get(jwtStateAtom)
    return jwt
  }
})

export type { jwtI }
