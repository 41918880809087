import { atom, selector } from "recoil"

export const tariffsStateAtom = atom({
  key: "tariffsStateAtom",
  default: false,
})

export const tariffsStateSelector = selector({
  key: "tariffsStateSelector",
  get: ({ get }) => {
    const tariffs = get(tariffsStateAtom)
    return tariffs
  }
})

