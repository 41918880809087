
const isCordova = () => {

  if (window.cordova) {
    return true

  }
  
  return false
}

export default isCordova