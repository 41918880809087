
import { useSetRecoilState, useRecoilValue } from 'recoil'

import { currentTrackStateAtom } from '@state/currentTrack'
import { trackIndexStateAtom } from '@state/trackIndex'
import { playPlayListIdStateAtom } from '@state/playPlayListId'
import { isPlayingStateAtom } from '@state/isPlaying'

import { playListsStateAtom, playListsI, TrackI } from '@state/playLists'

import { Get } from '@components/File'

import { usePrevious } from "@hooks/usePrevious"
import { useNext } from "@hooks/useNext"

import { useMediaSession } from "@hooks/useMediaSession"

import { useHowler } from '@hooks/useHowler'

const AppTitle:any = process.env.REACT_APP_TITLE

export const usePlayForIndex = () => {
  
  const { play, pause, changeTrack } = useHowler()

  const [Previous] = usePrevious()
  const [Next] = useNext()

  //const [MediaSession] = useMediaSession()

  const setTrackIndex = useSetRecoilState<any>(trackIndexStateAtom)

  const setCurrentTrack = useSetRecoilState<any>(currentTrackStateAtom)

  const setPlayPlayListId = useSetRecoilState<string>(playPlayListIdStateAtom)

  const setIsPlaying = useSetRecoilState<boolean>(isPlayingStateAtom)

  const playLists = useRecoilValue<playListsI>(playListsStateAtom)

  const playForId = async ( playListsKey:number, index:number) => {

    return new Promise<boolean>((resolve, reject) => {
        try {

          const track: any =playLists.lists[playListsKey].tracks[index]

          setIsPlaying(true)

          setTrackIndex(index)
          setCurrentTrack(track)
      
          setPlayPlayListId(playLists.lists[playListsKey].id)
          
          Get(track).then(async (trackGetData:any)=>{

            await changeTrack({
              src: [trackGetData],
              format: ['mp3'],
            })

            play()

            navigator.mediaSession.metadata = new MediaMetadata({
              title: track.name,
              //artist: AppTitle,
            })
/*
            const actionHandlers:any = [
              ['play',          () => { play() }],
              ['pause',         () => { pause() }],
              ['previoustrack', () => { Previous() }],
              ['nexttrack',     () => { Next() }],
              ['stop',          () => { pause() }],
            ]
            
            for (const [action, handler] of actionHandlers) {
              try {
                navigator.mediaSession.setActionHandler(action, handler);
              } catch (error) {
                console.log(`The media session action "${action}" is not supported yet.`);
              }
            }
*/
            //MediaSession()
            
            resolve(true)
          })
        } catch (error) {
          reject(error)
        }
    })
    
  }

  return [playForId] as const;
}