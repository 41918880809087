import { atom, selector } from "recoil"

export const currentTimeStateAtom = atom({
  key: "currentTimeStateAtom",
  default: 0,
})

export const currentTimeStateSelector = selector({
  key: "currentTimeStateSelector",
  get: ({ get }) => {
    const currentTime = get(currentTimeStateAtom)
    return currentTime
  }
})

