import { FC, useEffect, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import SwipeableViews from 'react-swipeable-views'

import ListIcon from '@mui/icons-material/List'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import LeakAddIcon from '@mui/icons-material/LeakAdd'
import MoodIcon from '@mui/icons-material/Mood'

const data = [
  {
    title: "Плейлисты",
    description: "Создавай плейлисты и делись ими со своими друзьями",
    button: "Далее",
    animation: <ListIcon fontSize='large' sx={{transform:'scale(5)'}}/>
  },
  {
    title: "С заботой о соседях ",
    description: "Можно настроить автоматическое отключение аудио после 22:00 и включение с 8:00",
    button: "Далее",
    animation: <ElectricalServicesIcon fontSize='large' sx={{transform:'scale(5)'}}/>,
  },
  {
    title: "Удаленное управление",
    description: "Управляй плеером через это же приложение на другом устройстве или через телеграм бота",
    button: "Далее",
    animation: <LeakAddIcon fontSize='large' sx={{transform:'scale(5)'}}/>,
  },
  {
    title: "Приятный экспириенс",
    description: "Наслаждайся отличным плеером!",
    button: "Стартуем",
    animation: <MoodIcon fontSize='large' sx={{transform:'scale(5)'}}/>,

  },
]

interface IOnboarding {}

const Onboarding: FC<IOnboarding> = () => {

  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(4)
  const maxSteps = data.length

  const end = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleNext = () => {
    if(activeStep===maxSteps) return end();
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    window.navigator?.vibrate?.(200)
  }
  /*
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  */
  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  if(activeStep===maxSteps) return (<></>);

  return (
    <Box sx={{
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      bottom: '0%',
      left: '50%',
      transform:'translate(-50%, -0%)',
      backgroundColor: 'RGBA(255, 255, 255, 1)',
      flexGrow: 1,
    }}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {data.map((step, index) => (
          <div key={index} style={{ height: '100vh'}}>
            {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'block',
                    overflow: 'hidden',
                    userSelect: 'none',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'block',
                      overflow: 'hidden',
                      userSelect: 'none',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)'
                    }}
                  />
                  <Box
                    sx={{
                      userSelect: 'none', 
                      position: 'absolute', 
                      bottom: '60%',
                      left: '50%',
                      transform:'translate(-50%, -70%)',
                    }}
                  >
                    { data[activeStep].animation }
                  </Box>

                  <Typography sx={{
                    userSelect: 'none', 
                    position: 'absolute', 
                    bottom: '32%',
                    left: '50%',
                    transform:'translate(-50%, -0%)',
                  }} variant="h4">
                    { data[activeStep].title }
                  </Typography>

                  <Typography sx={{
                    userSelect: 'none', 
                    position: 'absolute', 
                    bottom: '23%',
                    left: '50%',
                    transform:'translate(-50%, -0%)',
                  }} variant="body1">
                    { data[activeStep].description }
                  </Typography>
                  <Button
                    sx={{
                      userSelect: 'none',
                      position: 'absolute', 
                      bottom: '10%',
                      left: '50%',
                      transform:'translate(-50%, -0%)',
                    }}
                    variant="contained"
                    onClick={handleNext}
                    >
                      { data[activeStep].button }
                    </Button>
                </Box>
            ) : null}
            
          </div>
          
        ))}
      </SwipeableViews>
      <MobileStepper
      sx={{
        background: 'none',
        position: 'absolute',
        bottom: '5%',
        left: '50%',
        transform:'translate(-50%, -0%)',
      }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={<Box></Box>}
        backButton={<Box></Box>}
      />
    </Box>
  )
}

export default Onboarding
