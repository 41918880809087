import { atom, selector } from "recoil"

export const trackIndexStateAtom = atom({
  key: "trackIndexStateAtom",
  default: null,
})

export const trackIndexStateSelector = selector({
  key: "trackIndexStateSelector",
  get: ({ get }) => {
    const trackIndex = get(trackIndexStateAtom)
    return trackIndex
  }
})

