import { FC, useRef, useEffect, useState } from 'react'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button'

import useMediaQuery from '@mui/material/useMediaQuery'

import { useRecoilState } from 'recoil'

import { playListsStateAtom, playListsI, ListsI, TrackI } from '@state/playLists'
import { LoadFileCountUpdateStateAtom } from '@state/LoadFileCountUpdate'

import { useFileAdd } from '@hooks/useFile'

const DownloaderStartAudioList: FC = () => {

  let playListsKey: any

  const [ playLists, setPlayLists ] = useRecoilState<playListsI>(playListsStateAtom)

  for(let key in playLists.lists){
    if(playLists.lists[key].id === playLists.open){
      
      playListsKey = key

      break
    }
  }

  const tracks = useRef<any>([])
  
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ openSwipeableDrawer, setOpenSwipeableDrawer ] = useState<boolean>(false)

  const [ LoadFileCountUpdate, setLoadFileCountUpdate ] = useRecoilState<number>(LoadFileCountUpdateStateAtom)

  const [ count, setCount ] = useState<number>(0)

  useEffect(() => {

    console.log(`LoadFileCountUpdate: ${LoadFileCountUpdate}`)

    if(LoadFileCountUpdate === count){

      setLoading(false)
      setOpenSwipeableDrawer(false)
    }

  }, [LoadFileCountUpdate, count])

  useEffect(() => {

    let count:number = 0

    for( let key in playLists.lists ){

      if( playLists.lists[key].tracks.length > 0){

        count = count + playLists.lists[key].tracks.length
      }
    }

    if(count===0){

      setOpenSwipeableDrawer(true)
    }
  }, [])
  
  const [ FileChange ] = useFileAdd()

  const downloadAudio = async () => {

    setLoading(true)

    const audioListURL = '/assets/audio/tracks.json'

    const response = await fetch(audioListURL)
    const audioList: any[] = await response.json()
    
    setCount(audioList.length)

    for (let audioURL in audioList) {
      
      const audioBlob = await fetch(audioList[audioURL].url).then((res) => res.blob())

      const file:File = new File([audioBlob], audioList[audioURL].name, {type: audioBlob.type})

      if(undefined !== file){

        const track = await FileChange(file)

        tracks.current = [...tracks.current, track]
      }

      setLoadFileCountUpdate(count => count + 1)
    }

    setPlayLists({
      ...playLists, 
      lists: playLists.lists.map(( list:ListsI ) => {
        if ( list.id === playLists.open ){
          return {
            ...list, 
            tracks: [
              ...playLists.lists[playListsKey].tracks, 
              ...tracks.current
            ]
          }
        }
        return list
      })
    })
    
  }

  const matches = useMediaQuery('(min-width:370px)')

  return (<SwipeableDrawer
        anchor={`bottom`}
        open={openSwipeableDrawer}
        disableAutoFocus
        disableEnforceFocus
        onClose={()=>{

          if(!loading)
          setOpenSwipeableDrawer(false)
        }}
        onOpen={()=>{setOpenSwipeableDrawer(true)}}
        sx={{
            zIndex: 901 ,
            'justify-content': 'center','display': 'flex', 'align-items': 'center',
            '& .MuiPaper-root': {
              left: matches ? `calc(50% - 174px)` : 15,
            },
          }}
        >

          { openSwipeableDrawer && !loading &&

            <Box>

              <Stack direction="column" alignItems="center" spacing={1}> 

                <Typography variant="tinyText">Ваш аудио список пуст!</Typography>
                <Typography variant="tinyText">Загрузить начальный список антисосед?</Typography>

              </Stack>

              <Stack justifyContent="space-evenly" flexWrap="wrap" direction="row" spacing={3} sx={{ mt: 5}}>

                <Button variant="text" onClick={()=>{
                  if(!loading)
                  setOpenSwipeableDrawer(false)
                }}>
                  позже
                </Button>

                <Button variant="contained" onClick={()=>{
                  downloadAudio()
                }}>
                  Загрузить
                </Button>

              </Stack>

            </Box>
          }

          { openSwipeableDrawer && loading &&

            <Box sx={{ mb: 2}}>
              
              <Stack direction="column" alignItems="center" spacing={1}>

                <CircularProgress color="inherit" />

                <Typography variant="tinyText">Идет загрузка...</Typography>
                <Typography variant="tinyText">Загруженно: {LoadFileCountUpdate} из {count}</Typography>

              </Stack>

            </Box>
          }

      </SwipeableDrawer>)
}

export default DownloaderStartAudioList