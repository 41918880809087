import { useEffect } from 'react'

import Box from '@mui/material/Box'

import WallPaper from '@components/WallPaper'
import Widget from '@components/Widget'

import Header from '@components/Header'
import DisplayTrack from '@components/DisplayTrack'
import Controls from '@components/Controls'
import PlayLists from '@components/PlayLists'
import PlayListShow from '@components/PlayListShow'

import Settings from '@components/Settings'

import ListSpeedDial from '@components/ListSpeedDial'

import Onboarding from "@components/Onboarding"

import Backdrop from "@components/Backdrop"

import Auth from "@components/Auth"
import Tariffs from "@components/Tariffs"

import bytesToMB from "@utils/bytesToMB"

import DownloaderStartAudioList from "@components/DownloaderStartAudioList"

import ConnectingToDevice from "@components/ConnectingToDevice"
import ConnectingToDeviceQrReader from "@components/ConnectingToDeviceQrReader"

import { useSelectAudioOnOpenApp } from "@hooks/useSelectAudioOnOpenApp"

import { useMediaSession } from "@hooks/useMediaSession"

const AudioPlayer = () => {
  
  const [SelectAudioOnOpenApp] = useSelectAudioOnOpenApp()

  const [MediaSession] = useMediaSession()

  useEffect(() => {

    // https://www.xconvert.com/unit-converter/bytes-to-megabytes
    /*
    try {

      if (!navigator.mediaDevices?.enumerateDevices) {
        console.log("enumerateDevices() not supported.")
      } else {
        // List cameras and microphones.
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`)
            })
          })
          .catch((err) => {
            console.error(`${err.name}: ${err.message}`);
          })
      }
      
      // work for Firefox

      const fetchData = async () => {

        const quota =  await navigator.storage.estimate()

        //@ts-ignore
        const totalSpace = quota.quota | 0
        
        //@ts-ignore
        const usedSpace = quota.usage | 0
        
        console.log('totalSpace', totalSpace)
        console.log('usedSpace', usedSpace)

        //alert(totalSpace)
        
        const fileSizeInBytes = totalSpace  - usedSpace // 5 MB (5 * 1024 * 1024 bytes)

        const fileSizeInMB = bytesToMB(fileSizeInBytes)
        console.log(fileSizeInMB); // Output: 5
      }

      fetchData().catch(console.error)

      //work for chrome

      //@ts-ignore
        window.navigator.webkitTemporaryStorage.queryUsageAndQuota(function(usedSpace:any, totalSpace:any) { 
          console.log('Used space', usedSpace)
          console.log('Remaining space', totalSpace)
          //alert(remaining); 
        
          const fileSizeInBytes = totalSpace  - usedSpace // 5 MB (5 * 1024 * 1024 bytes)

          const fileSizeInMB = bytesToMB(fileSizeInBytes)
          console.log(fileSizeInMB) // Output: 5
        }, function(err:any) { 
          console.warn(err)
        });
      
    } catch (error) {}
    */

    SelectAudioOnOpenApp()

  }, [])

  useEffect(() => {
      
    SelectAudioOnOpenApp()

    //MediaSession()

  }, [])

  return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'normal',
            justifyContent: 'space-between',
            flexDirection: 'column',
            mt: -2,
            ml: 2,
            mr: 2,
            height: '100%'
          }}
        >
          <ListSpeedDial/>

          <Box sx={{ width: '100%', overflow: 'hidden' }}>

            <Widget> 

            <Auth/>
            
            <Tariffs/>

            <DownloaderStartAudioList/>
          
            <ConnectingToDevice/>
            <ConnectingToDeviceQrReader/>

            <Header/>

            <DisplayTrack {...{}}/>

            <Controls {...{}}/>

            <PlayLists/>

            <PlayListShow {...{}}/>

            <Settings/>

          </Widget>

          <Onboarding/>

        </Box>

        <WallPaper />

        <Backdrop />

      </Box>
    )
}

export default AudioPlayer
