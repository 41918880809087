import { FC, useState, memo } from 'react'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { playListsStateAtom, playListsI } from '@state/playLists'

import { trackIndexStateAtom } from '@state/trackIndex'
import { playPlayListIdStateAtom } from '@state/playPlayListId'
import { isPlayingStateAtom } from '@state/isPlaying'

import { moreStateAtom } from '@state/more'
import { currentMoreStateAtom } from '@state/currentMore'

import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'

import Typography from '@mui/material/Typography'

import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import PauseRounded from '@mui/icons-material/PauseRounded'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { usePlayForIndex } from '@hooks/usePlayForIndex'

import audioFormatTime from '@utils/audioFormatTime'

const ListsItem: FC<any> = ({
  item,
}) => {

  const [ play ] = usePlayForIndex()

  const trackIndex = useRecoilValue<any>(trackIndexStateAtom)

  const playPlayListId = useRecoilValue<string>(playPlayListIdStateAtom)

  const [ isPlaying, setIsPlaying ] = useRecoilState<boolean>(isPlayingStateAtom)

  const setMore = useSetRecoilState<boolean>(moreStateAtom)

  const setCurrentMore = useSetRecoilState<any>(currentMoreStateAtom)

  const [ loading, setLoading ] = useState<boolean>(false)

  let index: number = 0

  let playListsKey: any
  let playListsId: string = ''

  const playLists = useRecoilValue<playListsI>(playListsStateAtom)

  for(let key in playLists.lists){
    if(playLists.lists[key].id === playLists.open){
      
      playListsId = playLists.lists[key].id
      const tracks = playLists.lists[key].tracks

      playListsKey = key

      for (let i = 0; i < tracks.length; i++) {

        if( tracks[i].id === item.id){
          index = i
          break;
        }
      }
      break;
    }
  }

  return (
    <ListItem
      key={index}
      disableGutters
      secondaryAction={

        <Stack direction="row" alignItems="center"> 

          <Typography variant="tinyText">{ audioFormatTime(item.duration) }</Typography>
        
          <IconButton aria-label="more"onClick={()=>{
            setMore(true)
            setCurrentMore(item)
          }}>

            <MoreVertIcon/>
          </IconButton>

        </Stack>
      }>

        { loading ?
          <IconButton
            aria-label="play"
            >
            <CircularProgress color="inherit" size={24}/>
          </IconButton>
        :

          <IconButton
          aria-label="play"
          onClick={async()=>{

            if( trackIndex === index && playPlayListId === playListsId ){
              setIsPlaying(!isPlaying) 
              return;
            }

            setLoading(true)

            setTimeout(async () => {

              await play(playListsKey, index)
              setLoading(false)
            }, 500)
          }}>
                
            { playPlayListId !== playListsId ? (
              <PlayCircleIcon/>
            ) : (
              <>
                { trackIndex !== index ? (
                  <PlayCircleIcon/>
                ) : (
                  <>
                    {!isPlaying ? (
                      <PlayCircleIcon/>
                    ) : (
                      <PauseRounded/>
                    )}
                  </>
                )}
              </>
            )}

          </IconButton>
        }

        <Typography variant="body1"sx={{
          'overflow': 'hidden',
          'display': '-webkit-box',
          '-webkit-line-clamp': '2',
          '-webkit-box-orient': 'vertical',
          'text-overflow': 'ellipsis',
        }}>

          {item.name}

        </Typography>

    </ListItem>
  )
}


export default memo(ListsItem)