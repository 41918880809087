import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const userRandomVolumeStateAtom = atom({
  key: "userRandomVolumeStateAtom",
  default: {
    status: true,
    min: 50,
    max: 100,
  },
  effects_UNSTABLE: [persistAtom],

})

export const userRandomVolumeStateSelector = selector({
  key: "userRandomVolumeStateSelector",
  get: ({ get }) => {
    const userRandomVolume = get(userRandomVolumeStateAtom)
    return userRandomVolume
  }
})

