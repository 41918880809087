import { atom, selector } from "recoil"

export const isPlayingStateAtom = atom({
  key: "isPlayingStateAtom",
  default: false,
})

export const isPlayingStateSelector = selector({
  key: "isPlayingStateSelector",
  get: ({ get }) => {
    const isPlaying = get(isPlayingStateAtom)
    return isPlaying
  }
})

